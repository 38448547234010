import { useEffect, useRef, useState } from 'react';
import './MainSeedFlow.scss';
import classNames from 'classnames';
// import { DragPreviewLayer, Search } from '@cyberpion/cyberpion-ui';
import { DragDrop, STATUSES } from './DragDrop/DragDrop';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import {
  setIdsDict,
  setScrollPosition,
  setSeedData,
  setSeedSaveEnabled,
  setSelectedItems,
  updateIdsDict
} from 'features/MSSPSlice';
import { Common } from 'common/services/common.service';
import { Search } from '@cyberpion/cyberpion-ui';
import { setDelay, showError } from 'features/AlertSlice';

let initialScollerHeight = 0;

export function MainSeedFlow(props: any) {
  const [infoVisible, setInfoVisible] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  // const [approved, setApproved] = useState<any>([]);
  // const [rejected, setRejected] = useState<any>([]);
  // const [selected, setSelected] = useState<string[]>([]);
  const [data, setData] = useState<any>({});
  const [lastAction, setLastAction] = useState<any>({});
  const [scrollDisabled, setScrollDisabled] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const { seedData, scrollPosition, selectedItems } = useSelector((state: IReduxState) => state.mssp);
  const scrollerElement = useRef<any>(null);

  useEffect(() => {
    let _data = JSON.parse(JSON.stringify(data));
    for (let entity in _data) {
      _data[entity].domains.map((d: any) => {
        if (!search) {
          d.visible = true;
        } else if (d.domain.includes(search)) {
          d.visible = true;
        } else {
          d.visible = false;
        }
        return d;
      });
      _data[entity].names.map((n: any) => {
        if (!search) {
          n.visible = true;
        } else if (search && n.name.includes(search)) {
          n.visible = true;
        } else {
          n.visible = false;
        }
        return n;
      });
      dispatch(updateIdsDict({ id: entity, obj: _data[entity] }));
    }
    setData(_data);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (seedData.entities) {
      const data = prepareData(seedData.entities);
      setData(data);
      // } else {
      // setData([{ ionix_entity_id: 'fdsfdsf' }]);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seedData]);

  useEffect(() => {
    dispatch(setSeedData(Object.values(data)));
  }, [data, dispatch]);

  useEffect(() => {
    if (selectedItems.length && lastAction.source && lastAction.target) {
      const selectedItemsCopy = JSON.parse(JSON.stringify(selectedItems));
      dispatch(setSelectedItems([]));
      onFinishDrag(selectedItemsCopy, lastAction.source, lastAction.target);
      setLastAction({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAction]);

  const prepareData = (entities: any[]) => {
    const dataObj: any = {};
    const idsDict: any = {};
    let isAllCustomerApproved = false;
    if (entities.length) {
      isAllCustomerApproved = true;
    }
    entities.forEach((entity: any, index) => {
      let _entity = JSON.parse(JSON.stringify(entity));
      _entity.domains.map((d: any) => {
        d.id = Common.generateRandomId();
        d.visible = true;
        idsDict[d.id] = d;
        if (d.verdict !== STATUSES.CONFIRMED || d.data_source !== STATUSES.CUSTOMER) {
          isAllCustomerApproved = false;
        }
        return d;
      });
      _entity.names.map((n: any) => {
        n.id = Common.generateRandomId();
        n.visible = true;
        idsDict[n.id] = n;
        if (n.verdict !== STATUSES.CONFIRMED || n.data_source !== STATUSES.CUSTOMER) {
          isAllCustomerApproved = false;
        }
        return n;
      });
      idsDict[_entity.ionix_entity_id] = _entity;
      dataObj[_entity.ionix_entity_id] = _entity;
    });
    if (isAllCustomerApproved) {
      dispatch(setDelay(12000));
      dispatch(showError('Seed Data was not fetched'));
    }
    dispatch(setIdsDict(idsDict));
    return dataObj;
  };

  const onWheel = (e: any) => {
    if (scrollDisabled) {
      return;
    }
    if (scrollerElement.current && !props.isPopupOpen) {
      const height = scrollerElement.current.clientHeight;
      if (!initialScollerHeight) {
        initialScollerHeight = height;
      }
      const scroll = Math.max(Math.min(height - e.wheelDeltaY / 2, window.innerHeight - 160), initialScollerHeight);

      dispatch(setScrollPosition(scroll));
    }
  };

  useEffect(() => {
    document.removeEventListener('wheel', onWheel, false);
    document.addEventListener('wheel', onWheel, false);
    return () => {
      document.removeEventListener('wheel', onWheel, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isPopupOpen, scrollDisabled]);

  const onFinishDrag = (ids: string[], source: string, verdict: string) => {
    setData((prev: any) => {
      const _data = JSON.parse(JSON.stringify(prev));
      const updatedIdDict = []
      for (let entity in _data) {
        _data[entity].domains.map((d: any) => {
          if (ids.includes(d.id)) {
            d.verdict = verdict;
          }
          return d;
        });
        _data[entity].names.map((n: any) => {
          if (ids.includes(n.id)) {
            n.verdict = verdict;
          }
          return n;
        });
        updatedIdDict.push({ id: entity, obj: _data[entity] })
      }
      dispatch(updateIdsDict(updatedIdDict));

      return _data;
    });
    setLastAction({ source: source, target: verdict });
    dispatch(setSeedSaveEnabled(true));
  };

  const onSelection = (id: string) => {
    const copy = JSON.parse(JSON.stringify(selectedItems));
    if (copy.includes(id)) {
      copy.splice(copy.indexOf(id), 1);
    } else {
      copy.push(id);
    }
    dispatch(setSelectedItems(copy));
  };

  const onSelectAll = (list: []) => {
    dispatch(setSelectedItems(list));
  };

  const onSubmit = (value: string, type: string) => {
    let entities: any = [];
    let keys: any = {};
    let updatedData: any = JSON.parse(JSON.stringify(data));

    const itemsList = value.split('\n').filter((item: string) => item.trim() !== '');

    itemsList.forEach(item => {
      const id = Common.generateRandomId();
      let newObj: any = {
        scan_id: null,
        original_entity_id: null,
        ionix_entity_id: null,
        source: STATUSES.MANUAL,
        domains: [],
        names: [],
        creation_time: null
      };
      if (type === 'domain') {
        keys = { domain: item };
      } else if (type === 'name') {
        keys = { name: item, score: 100, confidence: null };
      }
      const obj = {
        data_source: STATUSES.MANUAL,
        verdict_source: STATUSES.MANUAL,
        verdict: STATUSES.CONFIRMED,
        parent_entity_id: id,
        id: id,
        visible: true,
        ...keys
      };
      newObj[type === 'domain' ? 'domains' : 'names'].push(obj);
      dispatch(updateIdsDict({ id: id, obj: obj }));

      updatedData[id] = newObj;
      entities = [...entities, { id: id, obj: newObj }];
      dispatch(setSeedSaveEnabled(true));
    });
    setData(updatedData);
    dispatch(updateIdsDict(entities));
  };

  return (
    <div
      ref={scrollerElement}
      className={classNames('MainSeedFlow', { scroll: !!scrollPosition })}
      style={{ height: scrollPosition }}
    >
      <div className="header">
        <h4>Select Seed Assets</h4>
        <div className="selection-handles" style={{ display: !!selectedItems.length ? 'flex' : 'none' }}>
          {selectedItems.length} Selected
          <button
            className="approve-btn"
            onClick={() => {
              onFinishDrag(selectedItems, STATUSES.SUGGESTED, STATUSES.CONFIRMED);
              dispatch(setSelectedItems([]));
            }}
          >
            <img src="/assets/images/ok-green.svg" alt="Ok" /> Approve
          </button>
          <button
            className="reject-btn"
            onClick={() => {
              onFinishDrag(selectedItems, STATUSES.SUGGESTED, STATUSES.REJECTED);
              dispatch(setSelectedItems([]));
            }}
          >
            <img src="/assets/images/trash.svg" alt="Trash" /> Reject
          </button>
          <img
            alt="Close"
            src="/assets/images/x.svg"
            onClick={() => {
              dispatch(setSelectedItems([]));
            }}
          />
        </div>
        <div style={{ display: !!selectedItems.length ? 'none' : 'block', width: 250 }}>
          <Search
            onChange={(value: string) => {
              setSearch(value);
            }}
          />
        </div>
      </div>
      <div className={classNames('info', { visible: infoVisible })}>
        <div>Review the proposed seed assets below, then simply drag your picks to the Approved or Rejected pile.</div>
        <img
          alt="Close"
          src="/assets/images/x.svg"
          onClick={() => {
            setInfoVisible(false);
          }}
        />
      </div>
      <DragDrop
        disableScroll={() => {
          setScrollDisabled(true);
        }}
        enableScroll={() => {
          setScrollDisabled(false);
        }}
        onFinishDrag={(item: string[], source: string, type: string) => onFinishDrag(item, source, type)}
        onSubmitNew={onSubmit}
        onSelection={onSelection}
        onSelectAll={onSelectAll}
        search={search}
        data={data}
      />
    </div>
  );
}
