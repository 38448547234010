import { IReduxState } from 'app/store';
import classNames from 'classnames';
import { Collapse } from 'pages/inventory/singleAsset/panelSide/components/collapse/Collapse';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ScanInfo } from './ScanInfo/ScanInfo';
import { SubMenu } from './SubMenu/SubMenu';
import { useFlags } from 'launchdarkly-react-client-sdk';
import './SidePanel.scss';
import { Menu } from './config';
import { LoginService } from 'login/services/login.service';
import { Common } from 'common/services/common.service';

export function SidePanel() {
  const [sidePanelOpen, SetSidePanelOpen] = useState(true);
  const { user } = useSelector((state: IReduxState) => state.login);

  const flags = useFlags();

  const canView = (item: any): boolean => {
    if (!user.role) {
      return false;
    }

    // if (item.showForMssp && (!user.is_mssp_user || !Common.getParamFromUrl('account'))) {
    //   return false;
    // }
    if (user.is_mssp_user && !Common.getParamFromUrl('account') && !item.showForMssp) {
      return false;
    }

    if (item.ionixBEFlag) {
      return user[item.ionixBEFlag];
    }

    if (item.viewPermissionLevel === 'admin') {
      return LoginService.isAdminOrAbove(user);
    }

    if (item.featureFlag) {
      return flags[item.featureFlag]
    }

    return true;
  };

  const isActive = (path: string) => {
    return window.location.href.includes(path);
  };

  if (!Object.keys(flags).length) {
    return null;
  }

  const isDisabledByFeatureFlag = (flag: string) => {
    if (!flag) {
      return false;
    }
    if (flag && flags[flag] === false) {
      return true;
    }
    return false;
  };

  return (
    <div className={`SidePanel ${sidePanelOpen ? 'open' : ''}`}>
      <div className="collapse-wrapper">
        <Collapse toggle={sidePanelOpen} onClick={() => SetSidePanelOpen(!sidePanelOpen)} />
      </div>
      <ul>
        {Object.keys(Menu).map((item: string, index: number) => {
          if (Menu[item].path?.length) {
            if (!canView(Menu[item]) || isDisabledByFeatureFlag(Menu[item].featureFlag)) {
              return <li key={index}></li>;
            }
            return (
              <li key={index}>
                <Link
                  className={classNames({
                    disabled: !Menu[item].path?.length,
                    active: isActive(Menu[item].path)
                  })}
                  to={Menu[item].path}
                >
                  {item}
                </Link>
              </li>
            );
          } else {
            return <SubMenu key={item} canView={(_item: any) => canView(_item)} text={item} items={Menu[item]} />;
          }
        })}
      </ul>
      <ScanInfo />
    </div>
  );
}
