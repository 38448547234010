// import React, { useState, useMemo } from 'react';
import { useTopPanelContext } from '../TopPanelContext';
import { CHART_TYPES } from '@cyberpion/cyberpion-ui';
import { DiscoveryInternalExpand, DiscoveryInternalCollapse } from './tabs/DiscoveryInternal';
import { DiscoveryDigitalSupplyExpand, DiscoveryDigitalSupplyCollapse } from './tabs/DiscoveryDigitalSupply';
import { DiscoveryLoginExpand, DiscoveryLoginCollapse } from './tabs/DiscoveryLogin';

import './toppanel-discovery.scss';

export const texts = {
  panel_I_title_org: 'org assets',
  active_org_assets: 'active org assets',
  // panel_II_title_org: 'Risk Distribution',
  fqdn_hosting_provider: 'FQDN hosting provider',
  panel_I_title_external: 'External Assets',
  panel_II_title_external: 'FQDN hosting provider',
  panel_I_title_login: 'Insecure Login Pages',
  panel_II_title_login: 'Invalid Certificates',
  domain: 'Domain',
  subdomain: 'Subdomain',
  ip: 'IP',
  critical: 'Critical',
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  no_risk: 'No Risk',
  accessible_over_http: 'Accessible over HTTP',
  last_30_days: 'Last 30 days',
  invalid_certificates: 'Invalid Certificates',
  currently_insecure: 'Currently Insecure',
  risk_distribution: 'Risk Distribution'
};
export const dataPanelProps = {
  titleTextSize: 12,
  weight: 500,
  textSize: 22,
  textColor: '#FFFFFF',
  family: 'Rajdhani',
  titleStyle: { color: '#A6acbe', paddingBottom: 4 }
};

export const textProps = {
  textSize: 12,
  textColor: '#FFFFFF',
  upper: true
};

export const chartOptions = {
  chart: {
    type: CHART_TYPES.AREA,
    backgroundColor: '#2c2c30',
    // width: null,
    height: 100,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 10,
    marginBottom: 26
  },
  xAxis: {
    visible: true,
    tickWidth: 1,
    tickLength: 5,
    tickmarkPlacement: 'on',
    tickColor: '#B7BBC5'
  },
  yAxis: {
    gridLineWidth: 0,
    labels: {
      enabled: false
    }
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 400
        },
        chartOptions: {
          chart: {
            marginBottom: 25,
            marginTop: 10
          },
          xAxis: {
            visible: true,
            labels: {
              enabled: false
            }
          }
        }
      },
      {
        condition: {
          minWidth: 401
        },
        chartOptions: {
          xAxis: {
            visible: true,
            labels: {
              enabled: true
            }
          }
        }
      }
    ]
  }
};

const TopPanelDiscovery = () => {
  const { tab, isOpen, data } = useTopPanelContext();

  if (!data || !Object.keys(data).length) return null;

  let topPanel;
  if (tab === 'internalAssets') {
    topPanel = isOpen ? <DiscoveryInternalExpand /> : <DiscoveryInternalCollapse />;
  }
  if (tab === 'digitalSupplyChain') {
    topPanel = isOpen ? <DiscoveryDigitalSupplyExpand /> : <DiscoveryDigitalSupplyCollapse />;
  }
  if (tab === 'loginAssets') {
    topPanel = isOpen ? <DiscoveryLoginExpand /> : <DiscoveryLoginCollapse />;
  }

  return <>{topPanel}</>;
};

export default TopPanelDiscovery;
