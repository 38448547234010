import { configureStore } from '@reduxjs/toolkit';
import gridReducer from '../features/GridSlice';
import loginReducer from '../features/LoginSlice';
import groupsReducer from '../features/GroupsSlice';
import subsidiariesReducer from '../features/SubsidiariesSlice';
import columnPickerReducer from '../features/columnPickerSlice';
import generalReducer from '../features/GeneralSlice';
import integrationsReducer from '../features/IntegrationsSlice';
import alertReducer from '../features/AlertSlice';
import infoPanelReducer from '../features/InfoPanelSlice';
import popSideReducer from '../features/popSideSlice';
import MSSPSlice from 'features/MSSPSlice';
import viewsReducer from '../features/ViewsSlice';
import downloadsReducer from '../features/DownloadsSlice';
import promptReducer from '../features/PromptSlice';
import topPanelReducer from '../features/TopPanelSlice';
import discoveryToolReducer from '../features/DiscoveryToolSlice';

export const store = configureStore({
  reducer: {
    grid: gridReducer,
    login: loginReducer,
    groups: groupsReducer,
    subsidiaries: subsidiariesReducer,
    columnPicker: columnPickerReducer,
    general: generalReducer,
    integrations: integrationsReducer,
    alert: alertReducer,
    infoPanel: infoPanelReducer,
    popSide: popSideReducer,
    mssp: MSSPSlice,
    views: viewsReducer,
    downloads: downloadsReducer,
    prompt: promptReducer,
    topPanel: topPanelReducer,
    discoveryTool: discoveryToolReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export interface IReduxState {
  [key: string]: {
    [key: string]: any;
  };
}

export type AppDispatch = typeof store.dispatch;

export default store;
