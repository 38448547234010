import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { useState } from 'react';
import { PopUp } from '../../../../common/components/popUp/PopUp';
import { AddParkedDomainsPopup } from './AddParkedDomainsPopUp/AddParkedDomainsPopup';
import { getRows } from 'features/GridSlice';
import { useSelector, useDispatch } from 'react-redux';
import { IReduxState } from '../../../../app/store';
import { Common } from '../../../../common/services/common.service';
import { RestApi } from '../../../../common/services/rest-api.service';
import { showSuccess, showError } from 'features/AlertSlice';
import { AppLoading } from '../../../../common/components/appLoading/appLoading';

export default function ParkedDomainsActions(props: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const { rows } = useSelector((state: IReduxState) => state.grid);
  const dispatch = useDispatch<any>();

  const hasNoSelection = !props.ids.length
  const isDisabled = rows.filter((row: any) => props.ids.includes(row.asset)).some((row: any) => row.parked_domain_source === 'Discovery')
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const updateParkedDomainAsset = ({ is_parked_domain }: { is_parked_domain: boolean | null }) => {
    setLoading(true);
    const url = Common.AddMsspAccountIfNeeded('settings/organization-management/parked-domains/');
    const payload = {
      assets: props.ids,
      is_parked_domain: is_parked_domain,
      parked_domain_source: 'Manual'
    };
    RestApi.setData(url, payload, 'POST').subscribe(
      (response: any) => {
        setLoading(false);
        handleClose();
        dispatch(getRows());
        dispatch(showSuccess('Updated Successfully'))
      },
      (error: any) => {
        setLoading(false);
        dispatch(showError('Failed to change asset status'))
      }
    );
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setAsActive = () => {
    updateParkedDomainAsset({is_parked_domain: false})
  };

  const resetToDefault = () => {
    updateParkedDomainAsset({is_parked_domain: null})
  };

  return (
    <div>
      <button className={`btn btn-main`} onClick={() => setPopupVisible(true)}>
        Add Additional Domains
      </button>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          color: 'black',
          fontWeight: 400,
          fontSize: '1.1rem',
          ml: '5px'
        }}
      >
        {loading && <AppLoading />}
        Actions
        <IconArrow active={false} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled={loading || hasNoSelection} onClick={setAsActive}>Set As Active</MenuItem>
        <MenuItem disabled={loading || hasNoSelection || isDisabled} onClick={resetToDefault}>Reset To Default</MenuItem>
      </Menu>
      <PopUp visible={popupVisible} setVisible={() => setPopupVisible(false)}>
        <AddParkedDomainsPopup onClose={() => setPopupVisible(false)} />
      </PopUp>
    </div>
  );
}