// @ts-nocheck
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { seeddata_mock } from 'common/mocks/seeddata_mock';
import { addHeaders } from 'common/services/rest-api.service';
import { env } from 'env';

export const addMSSPAccount = createAsyncThunk('groups/addMSSPAccount', async (data: any) => {
  const path = `${env.REACT_APP_BASE_URL}mssp/sub-account/company-info/`;
  const res = await fetch(path, {
    method: 'POST',
    mode: 'cors',
    headers: addHeaders('POST'),
    credentials: 'include',
    body: JSON.stringify(data)
  }).then(data => data.json());
  // debugger;
  return res;
});

export const scanCompany = createAsyncThunk('groups/scanCompany', async (data: any) => {
  const path = `${env.REACT_APP_BASE_URL}mssp/sub-account/company-scan/`;
  const res = await fetch(path, {
    method: 'POST',
    mode: 'cors',
    headers: addHeaders('POST'),
    credentials: 'include',
    body: JSON.stringify(data)
  }).then(data => data.json());
  // debugger;
  return res;
});

export const getSeedData = createAsyncThunk('groups/getSeedData', async () => {
  const path = `${env.REACT_APP_BASE_URL}settings/seed-data/`;
  const res = await fetch(path, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include'
  }).then(data => data.json());
  return res;
});

export const saveSeedData = createAsyncThunk('groups/setSeedData', async (data: any) => {
  const path = `${env.REACT_APP_BASE_URL}settings/seed-data/`;
  // PROD: PROD DATA *******************************************
  const res = await fetch(path, {
    method: 'POST',
    mode: 'cors',
    headers: addHeaders('POST'),
    credentials: 'include',
    body: JSON.stringify({ entities: data })
  }).then(data => data.json());
  // PROD: PROD DATA ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  // MOCK: TESTING USAGE ***************************************
  // const res = await fetch(path, {
  //   method: 'POST',
  //   mode: 'cors',
  //   headers: addHeaders('POST'),
  //   credentials: 'include',
  //   body: JSON.stringify({ entities: seeddata_mock.entities })
  // }).then(data => data.json());
  // MOCK: ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  return res;
});

export const getCompanyConf = createAsyncThunk('groups/getCompanyConf', async () => {
  const path = `${env.REACT_APP_BASE_URL}mssp/mssp-company/`;
  const res = await fetch(path, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include'
  }).then(data => data.json());
  return res;
});

export const getAccountInfo = createAsyncThunk('groups/getAccountInfo', async (companyName: string) => {
  const path = `${env.REACT_APP_BASE_URL}mssp/sub-account/${companyName}/`;
  const res = await fetch(path, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include'
  }).then(data => data.json());
  return res;
});

export const editCompany = createAsyncThunk('groups/editCompany', async (data: any) => {
  const path = `${env.REACT_APP_BASE_URL}mssp/sub-account/${data.companyName}/`;
  const res = await fetch(path, {
    method: 'PATCH',
    mode: 'cors',
    headers: addHeaders('PATCH'),
    credentials: 'include',
    body: JSON.stringify(data.changeParams)
  }).then(data => {
    return data.json();
  });
  // debugger;
  return res;
});

const MSSPSlice = createSlice({
  name: 'mssp',
  initialState: {
    showEditModal: false,
    showDeactivateModal: false,
    showErrorModal: false,
    showActivationModal: false,
    seedData: [],
    accountInfo: {},
    companyConfig: {},
    idsDict: {},
    loading: false,
    handledSeedData: [],
    seedSaveEnabled: false,
    selectedItems: [],
    scrollPosition: 0,
    errorCode: '',
    seedDataAdditionalInfoToolTip: {},
    addAccountRejected: false
  },
  reducers: {
    togglEditModal(state) {
      state.showEditModal = !state.showEditModal;
    },
    toggleDeactivateModal(state) {
      state.showDeactivateModal = !state.showDeactivateModal;
    },
    toggleErrorModal(state, action) {
      state.errorCode = action.payload;
      state.showErrorModal = !state.showErrorModal;
    },
    toggleActivateModal(state) {
      state.showActivationModal = !state.showActivationModal;
    },
    setAccountInfo(state, action) {
      state.accountInfo = action.payload;
    },
    pushSeedData(state, action) {
      state.handledSeedData.push(action.payload);
    },
    setSeedData(state, action) {
      state.seedData = action.payload;
    },
    setSeedSaveEnabled(state, action) {
      state.seedSaveEnabled = action.payload;
    },
    setScrollPosition(state, action) {
      state.scrollPosition = action.payload;
    },
    setIdsDict(state, action) {
      state.idsDict = action.payload;
    },
    updateIdsDict(state, action) {
      if (Array.isArray(action.payload)) {
        const bulk = action.payload.reduce((acc, curr) => {
          return { ...acc, [curr.id]: curr.obj };
        }, {});
        state.idsDict = { ...state.idsDict, ...bulk };
      } else {
        state.idsDict = { ...state.idsDict, [action.payload.id]: action.payload.obj };
      }
    },
    setSelectedItems(state, action) {
      state.selectedItems = action.payload;
    },
    setSeedDataAdditionalInfoToolTip(state, action) {
      state.seedDataAdditionalInfoToolTip = action.payload;
    }
  },
  extraReducers: {
    [getSeedData.fulfilled]: (state, { payload }) => {
      // PROD: PROD DATA ************
      state.seedData = payload;
      // PROD: ^^^^^^^^^^^^^^^^^^^^^^

      // MOCK: TESTING USAGE ********
      // state.seedData = seeddata_mock;
      // MOCK: ^^^^^^^^^^^^^^^^^^^^^^
    },
    [getCompanyConf.fulfilled]: (state, { payload }) => {
      state.companyConfig = payload;
      state.loading = false;
    },
    [getCompanyConf.pending]: state => {
      state.loading = true;
    },
    [getAccountInfo.fulfilled]: (state, { payload }) => {
      state.accountInfo = payload;
      state.loading = false;
    },
    [getAccountInfo.pending]: state => {
      state.loading = true;
    },
    [addMSSPAccount.fulfilled]: state => {
      state.addAccountRejected = false;
    },
    [addMSSPAccount.rejected]: state => {
      state.addAccountRejected = true;
    }
  }
});

const { actions, reducer } = MSSPSlice;
export const {
  togglEditModal,
  toggleDeactivateModal,
  toggleErrorModal,
  toggleActivateModal,
  setAccountInfo,
  pushSeedData,
  setSeedSaveEnabled,
  setSeedData,
  setScrollPosition,
  setSelectedItems,
  setIdsDict,
  updateIdsDict,
  setSeedDataAdditionalInfoToolTip
} = actions;
export default reducer;
