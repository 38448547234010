import { Links } from 'common/components/links/Links';
import { RestApi } from 'common/services/rest-api.service';
import { useEffect, useState } from 'react';
import PanelSide from './panelSide/PanelSide';
import { Paths } from 'common/constants/paths';
import { TABSET } from '../config';
import { TABSET_SINGLE_ASSET } from './config';
import { Common, updateHeader } from 'common/services/common.service';
import { ManageTabs } from './ManageTabs';
import { IUpdateLink } from './interface';
import TopPanel from 'common/components/TopPanel/TopPanel';
import TOP_PANEL_PAGES from 'common/constants/topPanelPages';
import './singleAsset.scss';
import classNames from 'classnames';
import { ManageNotData } from 'common/components/manageNotData/ManageNotData';
import { useDispatch, useSelector } from 'react-redux';
import { NotFound } from 'notFound/NotFound';
import { PopSide } from 'common/components/PopSide/PopSide';
import FqdnsInfoPanel from 'common/components/grid/components/InfoPanel/components/FqdnsInfoPanel';
import { resetInfoPanel } from 'features/InfoPanelSlice';

export function SingleAsset(props: any) {
  const isMobileAsset = props.match.params.page === 'mobileAssets';
  const { globalFilter } = useSelector((state: any) => state.general);
  const { infoPanelAsset } = useSelector((state: any) => state.infoPanel);
  const { filters } = useSelector((state: any) => state.grid);
  const page: string = props.match.params.page;
  const id: string | number = props.match.params.id;
  const [metaData, setMetaData] = useState<any>({});
  const dispatch = useDispatch<any>();
  const [data, setData] = useState<any>({
    page: 'action_items',
    data: [],
    showSingleAsset: false,
    showNotFoundPage: false,
    showAssetRemoved: false,
    tab: props.match.params.tab
  });

  useEffect(() => {
    checksPagePath()
      ? getData()
      : setData({ ...data, showNotFoundPage: true, showAssetRemoved: false, showSingleAsset: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    const tab = props.match.params.tab;
    if (tab === 'connections') {
      const url = checkShowSummary(tab);
      if (!!url) {
        RestApi.getData(url).subscribe(
          (response: any) => {
            setData({
              ...data,
              dataTabs: response
            });
            updatePageHeader(data.tab || props.params.match.tab);
          },
          error => {}
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const updatePageHeader = (tab: string) => {
    const currentTabTitle = TABSET_SINGLE_ASSET[page][tab]?.title;
    const assetName = getAssetName();
    const pageTitle = Common.camelCaseToTitle(page.replace('internal', 'org'));
    const headerArray = ['Discovery'];
    if (pageTitle) {
      headerArray.push(pageTitle);
    }
    if (assetName) {
      headerArray.push(assetName);
    }
    if (currentTabTitle) {
      headerArray.push(currentTabTitle);
    }
    updateHeader.sendHeaderText(headerArray.join(' / '));
  };

  const getAssetName = () => {
    if (data.body && data.body[0]) {
      return data.body[0]['Asset Name'];
    }
    return null;
  };

  useEffect(() => {
    if (data.tab !== props.match.params.tab) {
      const newTab = props.match.params.tab;
      updateSelectTab(newTab);
      updatePageHeader(newTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match, data.tab]);

  //@description = Checks if the page name exists in single assets tabs
  //@return = boolean
  const checksPagePath = (): boolean => {
    if (!page || !id) {
      return false;
    }

    if (!TABSET[page]) {
      return false;
    }

    return true;
  };

  const initUrls = () => {
    let urls: any = {
      detailed: `${Paths[page]}${id}${props.match.params.page !== 'mobileAssets' ? '/detailed/' : '/'}`
    };
    let tab = props.match.params.tab;
    let urlSummary = checkShowSummary(tab);
    if (urlSummary) {
      urls.dataTabs = urlSummary;
    }
    return urls;
  };

  const checkShowSummary = (tab: string) => {
    let f = '';
    for (let key in filters) {
      if (f === '') f = '?';
      if (f !== '?') {
        f += '&';
      }
      f += `${key}${filters[key].type}=${filters[key].value}`;
    }
    let showSummary = TABSET_SINGLE_ASSET[page][tab].showSummary;
    if (showSummary) {
      return `${Paths[page]}${id}/${tab}/summary${f}`;
    }
  };

  const getTabs = (_data: any) => {
    let tabs = updateLink(Common.cloneData(TABSET_SINGLE_ASSET[page]));
    if (isMobileAsset && !!_data.type && !!_data.type.toLowerCase().includes('ios')) {
      delete tabs.tests;
    }
    return tabs;
  };

  const getData = () => {
    getMetaData();
    RestApi.getDataPage(initUrls()).subscribe(
      (response: any) => {
        if (!response.detailed) {
          setData({ ...data, showNotFoundPage: true });
        } else {
          setData({
            data: response.detailed,
            dataTabs: response.dataTabs,
            showSingleAsset: true,
            tabs: getTabs(response.detailed),
            tab: data.tab || props.params.match.tab
          });
          updatePageHeader(data.tab || props.params.match.tab);
        }
      },
      error => {
        try {
          handleError(error);
        } catch (err) {
          setData({ ...data, showNotFoundPage: true, showSingleAsset: false });
        }
      }
    );
  };

  const getMetaData = () => {
    RestApi.getData(`${Paths[page]}${id}/metadata/${globalFilter ? `?global_filter=${globalFilter}` : ''}`).subscribe(
      (response: any) => {
        if (response) {
          setMetaData(response);
        }
      }
    );
  };

  const handleError = (err: any) => {
    const msg = JSON.parse(err.message)[0].detail;
    if (msg.startsWith('Asset Removed')) {
      setData({ ...data, showAssetRemoved: true, showNotFoundPage: false, showSingleAsset: false });
    } else {
      setData({ ...data, showNotFoundPage: true, showAssetRemoved: false, showSingleAsset: false });
    }
  };

  const updateSelectTab = (tab: string) => {
    let urlSummary = checkShowSummary(tab);
    if (urlSummary) {
      if (tab !== data.tab) {
        RestApi.getDataPage({ dataTabs: urlSummary }).subscribe(
          (response: any) => {
            setData({ ...data, dataTabs: response.dataTabs, tab: tab });
          },
          error => {
            try {
              handleError(error);
            } catch (err) {
              setData({ ...data, showNotFoundPage: true, showSingleAsset: false });
            }
          }
        );
      }
    } else {
      setData({ ...data, dataTabs: undefined, tab: tab });
    }
  };

  // @description: Updates the path by page name and by ID
  const updateLink = (data: IUpdateLink) => {
    let tabs: IUpdateLink = {};
    Object.keys(data).forEach(key => {
      tabs[key] = data[key];
      tabs[key].link = `${data[key].link}/${page}/${id}/${key}`;
      tabs[key].gridPath = `${Paths[page]}${id}/${data[key].key}/`;
      tabs[key].category = props.match.params.page;
    });
    return tabs;
  };

  const showTopPanel =
    !!data.dataTabs &&
    ((data.tab === 'evidence' && !!metaData?.evidence?.count && metaData?.evidence?.count > 0) ||
      data.tab === 'connections');

  return (
    <>
      {data.showSingleAsset && !data.showNotFoundPage && !data.showAssetRemoved && (
        <div className={classNames('singleAsset', { is_virtual: data.data?.cloud_asset?.is_virtual })}>
          <PanelSide data={data.data} isMobileAsset={isMobileAsset} />
          {!data.data?.cloud_asset?.is_virtual ? (
            <div className="singleAsset-scope">
              <div>
                <Links selectTab={data.tab} tabs={data.tabs} metaData={metaData} />
                {showTopPanel && <TopPanel page={TOP_PANEL_PAGES.SINGLE_ASSET} tab={data.tab} data={data.dataTabs} />}
              </div>
              <div className="singleAsset-tab-inner">
                <ManageTabs data={data.tabs[props.match.params.tab]} assetName={data.data.asset} />
              </div>
            </div>
          ) : (
            <div className="singleAsset-scope">
              <ManageNotData type="org_score" />
            </div>
          )}
        </div>
      )}
      {data.showAssetRemoved && <ManageNotData type="single_asset" />}
      {data.showNotFoundPage && <NotFound type="404" />}
      {!!infoPanelAsset && (
        <PopSide
          visible={!!infoPanelAsset}
          onHide={() => {
            dispatch(resetInfoPanel());
          }}
        >
          <FqdnsInfoPanel />
        </PopSide>
      )}
    </>
  );
}
