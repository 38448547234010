import React, { useState } from 'react';
import { Divider, Grid } from '@mui/material';
import CollapseFields from '../CollapseFields/CollapseFields';
import './panelSideBody.scss';
import { Text, TreeDiagram } from '@cyberpion/cyberpion-ui';
import CollapsedList from '../CollapsedList/CollapsedList';
import classNames from 'classnames';
import { ToolTip } from 'common/components/toolTip/toolTip';
import CollaspsedDiagram from '../CollaspsedDiagram/CollapsedDiagram';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';
import { setInfoPanelAsset } from 'features/InfoPanelSlice';
import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';

export const texts = {
  registrar: 'Registrar',
  registrant_organization: 'Registrant Organization',
  registrant_contact_name: 'Registrant Contact Name',
  whois_name_servers: 'WHOIS Name Servers',
  whois_status: 'WHOIS Status',
  domain_expiration_date: 'Domain Expiration Date',
  whois_emails: 'WHOIS Emails',
  alexa_rank: 'Alexa Rank',
  majestic_rank: 'Majestic Rank',
  umbrella_rank: 'Umbrella Rank',
  ip: 'IP',
  ip_network: 'IP Network',
  ip_network_name: 'IP Network Name',
  ip_network_org_id: 'IP Network Org ID',
  ip_network_org_name: 'IP Network Org Name',
  ip_network_country: 'IP Network Country',
  ip_network_source: 'IP Network Source',
  open_ports: 'Open Ports',
  cloud_provider: 'Cloud Provider',
  category: 'Category',
  type: 'Cloud Asset Type',
  region: 'Region',
  is_public_facing: 'Public Facing',
  ports: 'Ports',
  custom_domains: 'Custom Domains',
  internet_paths: 'Internet Paths',
  internal_networks: 'Internal Networks',
  tags: 'Cloud Tags',
  environment_variables: 'Environment Variables',
  subscription_id: 'Subscription ID',
  resource_id: 'Resource ID',
  is_parked_domain: 'Parked Domain',
  parked_domain_source: 'Parked Domain Source',
};
interface IPanelSideBody {
  panelData: any;
  type: string;
  tags: string[];
  asset: string;
  groups: string[];
  hosting: string[];
}

const fields = [
  'registrar',
  'registrant_organization',
  'registrant_contact_name',
  'whois_name_servers',
  'whois_status',
  'domain_expiration_date',
  'whois_emails',
  'alexa_rank',
  'majestic_rank',
  'umbrella_rank',
  'ip_network',
  'ip_network_country',
  'ip_network_name',
  'ip_network_org_id',
  'ip_network_org_name',
  'ip_network_source',
  'open_ports',
  'cloud_provider',
  'category',
  'type',
  'region',
  'is_public_facing',
  'ports',
  'custom_domains',
  'internet_paths',
  'internal_networks',
  'tags',
  'environment_variables',
  'subscription_id',
  'resource_id',
  'is_parked_domain',
  'parked_domain_source',
];

const TAGS_TO_SHOW = 3;
const GROUPS_TO_SHOW = 2;
const HOSTING_TO_SHOW = 2;

const PanelSideBody: React.FC<IPanelSideBody> = ({ panelData, type, tags, groups, asset, hosting }) => {
  const [isTagsExpand, setIsTagsExpand] = useState(false);
  const [isGroupsExpand, setIsGroupsExpand] = useState(false);
  const [isHostingExpand, setIsHostingExpand] = useState(false);
  const dispatch = useDispatch<any>();
  const areIPs = panelData && panelData.ips && panelData.ips.length > 0;
  const areFQDNs = panelData && panelData.fqdns && panelData.fqdns.length > 0;
  const subItemsData = areIPs ? panelData.ips : panelData.fqdns;
  const getPanelBodyTitle = () => {
    if (type === 'cloud_asset') {
      return 'GENERAL INFO';
    }
    return type === 'ip' ? 'IP INFO' : 'APEX DOMAIN INFO';
  };

  const handleExpand = () => {
    setIsTagsExpand(expand => !expand);
    const ele = document.querySelector('.panel-body-tags');
    if (!!ele) ele.classList.toggle('collapsed');
  };

  const handleGroupsExpand = () => {
    setIsGroupsExpand(expand => !expand);
    const ele = document.querySelector('.panel-body-groups');
    if (!!ele) ele.classList.toggle('collapsed');
  };

  const handleHostingExpand = () => {
    setIsHostingExpand(expand => !expand);
    const ele = document.querySelector('.panel-body-hostings');
    if (!!ele) ele.classList.toggle('collapsed');
  };

  const isObject = (obj: any) => {
    return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
  };

  const shouldDisplayTitle = (data: any) => {
    if (!data) {
      return false;
    }
    if (Array.isArray(data) && !data.length) {
      return false;
    }
    if (isObject(data) && !Object.keys(data).length) {
      return false;
    }
    return true;
  };

  return (
    <Grid container className="panel-body-container">
      <Grid item sm={12} className="panel-body-title">
        {getPanelBodyTitle()}
        {panelData.cloud_resource_url ? (
          <LinkNewTab
            to={panelData.cloud_resource_url}
            text={
              <div>
                <img
                  src="/assets/images/cloud-link.svg"
                  alt="Go To Asset"
                  data-tip="Go To Asset"
                  data-for="go-to-asset-tooltip"
                />
                <ToolTip place="bottom" effect="solid" id="go-to-asset-tooltip" offset={{ left: 80, top: 55 }} />
              </div>
            }
          />
        ) : (
          ''
        )}
      </Grid>
      {!!tags && !!tags.length && (
        <>
          <Divider className="panel-body-divider" />
          <Text
            textSize={11}
            textColor="#8c909a"
            style={{ marginTop: 12, marginLeft: 24 }}
          >{`Tags (${tags.length})`}</Text>
          <Grid container className="panel-body-tags collapsed">
            {tags.map((tag: any, index) => (
              <div key={index} className="panel-body-tag">
                {typeof tag === 'string' ? tag : tag?.name}
              </div>
            ))}
          </Grid>
          {tags.length > TAGS_TO_SHOW && (
            <div className="panel-body-tags-expand" onClick={handleExpand}>
              {isTagsExpand ? 'Show Less' : 'Show All'}
            </div>
          )}
        </>
      )}
      {!!groups && !!groups.length && (
        <>
          <Divider className="panel-body-divider" />
          <Text
            textSize={11}
            textColor="#8c909a"
            style={{ marginTop: 12, marginLeft: 24 }}
          >{`Groups (${groups.length})`}</Text>
          <Grid container className="panel-body-groups collapsed">
            {groups.map((group: any, index) => (
              <div key={index} className="panel-body-group">
                {typeof group === 'string' ? group : group?.name}
              </div>
            ))}
          </Grid>
          {groups.length > GROUPS_TO_SHOW && (
            <div className="panel-body-groups-expand" onClick={handleGroupsExpand}>
              {isGroupsExpand ? 'Show Less' : 'Show All'}
            </div>
          )}
        </>
      )}
      <Grid item sm={12}>
        {fields
          .filter(field => !!panelData[field] && shouldDisplayTitle(panelData[field]))
          .map(field => (
            <React.Fragment key={field}>
              <Divider className="panel-body-divider" />

              <Grid container key={field} sx={{ padding: '8px 0' }}>
                <Grid item sm={12} className="panel-body-field">
                  {texts[field as keyof typeof texts]}
                  {['environment_variables', 'tags'].includes(field)
                    ? ` (${Object.keys(panelData[field]).length})`
                    : ''}
                </Grid>

                <Grid item sm={12} className={classNames('panel-body-field-text', field)}>
                  {field === 'internet_paths' ? (
                    <CollaspsedDiagram>
                      <TreeDiagram data={panelData[field]} type="internet_paths" linkType="internal" />
                    </CollaspsedDiagram>
                  ) : field === 'internal_networks' ? (
                    <CollaspsedDiagram>
                      <TreeDiagram data={panelData[field]} type="cloud_assets" linkType="internal" />
                    </CollaspsedDiagram>
                  ) : typeof panelData[field] === 'boolean' ? (
                    panelData[field] ? (
                      'Yes'
                    ) : (
                      'No'
                    )
                  ) : Array.isArray(panelData[field]) ? (
                    panelData[field].join(', ')
                  ) : isObject(panelData[field]) ? (
                    <CollapsedList list={panelData[field]} className={field} />
                  ) : (
                    panelData[field]
                  )}
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
      {hosting && !!hosting.length && (
        <>
          <Divider className="panel-body-divider" />
          <Text
            textSize={11}
            textColor="#8c909a"
            style={{ marginTop: 12, marginLeft: 24 }}
          >{` Hosting Provider (${hosting.length})`}</Text>
          <Grid container className="panel-body-hostings collapsed">
            {hosting.map((host: any, index: number) => (
              <Tooltip
                key={index}
                title={<Text textColor="#FFF">{host}</Text>}
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#4D4C53',
                      color: '#FFFFFF',
                      padding: '3px 6px',
                      marginBottom: '0px !important',
                      '& .MuiTooltip-arrow': {
                        color: '#4D4C53'
                      },
                      border: '1px solid black',
                      borderRadius: 1
                    }
                  }
                }}
              >
                <div
                  className="panel-body-hosting"
                  style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                  {host}
                </div>
              </Tooltip>
            ))}
          </Grid>
          {hosting.length > HOSTING_TO_SHOW && (
            <div className="panel-body-hostings-expand" onClick={handleHostingExpand}>
              {isHostingExpand ? 'Show Less' : 'Show All'}
            </div>
          )}
        </>
      )}
      <Divider className="panel-body-divider" />
      {panelData && (areIPs || areFQDNs) && (
        <Grid container>
          <Grid item className="collapse-fields-title" sm={12}>
            IP INFO
          </Grid>
          <Divider className="panel-body-divider" />
          {subItemsData.map((subItem: string) => (
            <Grid item sm={12} key={subItem}>
              <CollapseFields collapseItem={subItem} />
              <Divider className="panel-body-divider" />
            </Grid>
          ))}
        </Grid>
      )}
      {type === 'ip' && panelData.fqdns.count && (
        <div style={{ width: '100%' }}>
          <Grid item sm={12} className="panel-body-title">
            RELATED FQDN's
          </Grid>
          <ul style={{ listStyleType: 'none', margin: '8px 0 0 25px', padding: 0 }}>
            {panelData.fqdns.fqdns.map((fqdn: string, index: number) => {
              return (
                <li style={{ marginBottom: 5, wordBreak: 'break-all', paddingRight: 15 }} key={index}>
                  <LinkNewTab
                    to={`/pages/singleAsset/internalAssets/${fqdn}/actionItems`}
                    text={
                      <Text textSize={11} weight={300}>
                        {fqdn}
                      </Text>
                    }
                    className="fqdn-link"
                  />
                </li>
              );
            })}
          </ul>
          {panelData.fqdns.count > 3 && (
            <div
              className="fqdns-count"
              onClick={() => {
                dispatch(setInfoPanelAsset(asset));
              }}
            >
              + {panelData.fqdns.count - 3}
            </div>
          )}
        </div>
      )}
    </Grid>
  );
};

export default PanelSideBody;
