import { IconAddTag } from 'common/components/Icons/IconAddTag/IconAddTag';
import { IconExportCSV } from 'common/components/Icons/IconExportCSV/IconExportCSV';
import { IconRemoveTag } from 'common/components/Icons/IconRemoveTag/IconRemoveTag';
import { PathNames } from 'common/constants/pathNames';
interface ITabset {
  [key: string]: ITab;
}
interface ITab {
  title: string;
  key: string;
  link: string;
  IconComponent: any;
  permissionOpen: boolean;
  active: boolean;
  showSummary: boolean;
  columnPickerPath: string;
  gridPath: string;
  tabPath: string;
  count: number;
  lockedTo?: string[];
  featureFlag?: string;
  defaultView?: string;
  savedViews?: boolean;
}
interface IActionOption {
  IconComponent: React.ComponentType;
  label: string;
  ff?: string;
}
type IActionSelect = {
  header: string;
  options: IActionOption[];
  apiPath: string;
};


export const TABSET: ITabset = {
  internalAssets: {
    title: 'Org Assets',
    key: 'internal_assets',
    IconComponent: null,
    link: '/pages/discovery/internalAssets',
    defaultView:
      '/pages/discovery/internalAssets?fields=id%2Crisk_score%2Casset%2Ctype%2Cimportance%2Chosting_provider%2Ctechnologies%2Cfirst_seen%2Cservice_type%2Cservice%2Ctags%2Cgroups',
    permissionOpen: true,
    active: false,
    showSummary: true,
    columnPickerPath: PathNames.internalAssetsColumns,
    gridPath: PathNames.internalAssets,
    tabPath: PathNames.internalAssetsSummary,
    count: 0,
    savedViews: true
  },
  digitalSupplyChain: {
    title: 'Digital Supply Chain',
    key: 'external_assets',
    IconComponent: null,
    link: '/pages/discovery/digitalSupplyChain',
    defaultView:
      '/pages/discovery/digitalSupplyChain?fields=id%2Crisk_score%2Casset%2Cconnected_assets%2Cconnections_types%2Chosting_provider%2Ctechnologies%2Cfirst_seen%2Cgroups',
    permissionOpen: true,
    active: false,
    showSummary: true,
    columnPickerPath: PathNames.digitalSupplyChainColumns,
    gridPath: PathNames.digitalSupplyChain,
    tabPath: PathNames.digitalSupplyChainSummary,
    count: 0,
    savedViews: true
  },
  publicCloud: {
    title: 'Cloud Integration',
    key: 'cloud_assets',
    IconComponent: null,
    link: '/pages/discovery/publicCloud/?filters=%7B"is_public_facing"%3A1%2C"is_virtual"%3A0%7D',
    defaultView:
      '/pages/discovery/publicCloud/?fields=id%2Casset%2Cresource_id%2Ccloud_provider%2Ccategory%2Ctype%2Cgroups&filters%7B"is_public_facing"%3A1%2C"is_virtual"%3A0%7D',
    permissionOpen: true,
    active: false,
    showSummary: false,
    columnPickerPath: PathNames.publicCloudColumns,
    gridPath: PathNames.publicCloud,
    tabPath: '',
    count: 0,
    featureFlag: 'isCcv',
    savedViews: false
  },
  loginAssets: {
    title: 'Login Assets',
    key: 'login_assets',
    IconComponent: null,
    link: '/pages/discovery/loginAssets',
    defaultView:
      '/pages/discovery/loginAssets?fields=id%2Crisk_score%2Casset%2Clogin_url%2Clogin_type%2Cis_over_http%2Cis_over_https%2Cis_valid_certificate%2Cservice_type%2Cservice%2Coperated_by%2Cfirst_seen%2Cgroups',
    permissionOpen: true,
    active: false,
    showSummary: true,
    columnPickerPath: PathNames.loginAssetsColumns,
    gridPath: PathNames.loginAssets,
    tabPath: PathNames.loginAssetsSummary,
    count: 0,
    savedViews: true
  },
  managedDomains: {
    title: 'Managed Domains',
    key: 'managed_domains',
    IconComponent: null,
    link: '/pages/discovery/managedDomains',
    defaultView:
      '/pages/discovery/managedDomains?fields=id%2Crisk_score%2Casset%2Cservice_provider%2Cvulnerability_count%2Cgroups',
    permissionOpen: true,
    active: false,
    showSummary: false,
    columnPickerPath: PathNames.managedDomainsColumns,
    gridPath: PathNames.managedDomains,
    tabPath: PathNames.managedDomainsSummary,
    count: 0,
    savedViews: true
  },
  certificates: {
    title: 'Certificates',
    key: 'certificates',
    IconComponent: null,
    link: '/pages/discovery/certificates',
    defaultView:
      '/pages/discovery/certificates?fields=id%2Csubject_common_name%2Cissuer_common_name%2Cissued%2Cexpires%2Crelated_assets%2Crelated_wildcards%2Cinvalid_fqdns%2Cgroups',
    permissionOpen: true,
    active: false,
    showSummary: false,
    columnPickerPath: PathNames.certificatesColumns,
    gridPath: PathNames.certificates,
    tabPath: PathNames.certificatesSummary,
    count: 0,
    savedViews: true
  },
  unlinkedAssets: {
    title: 'Unlinked Assets',
    key: 'unlinked_assets',
    IconComponent: null,
    link: '/pages/discovery/unlinkedAssets',
    defaultView:
      '/pages/discovery/unlinkedAssets?fields=id%2Casset%2Casset_type%2Cports%2Cevidence_types%2Ccertainty%2Crelated_org_fqdns%2Ckeywords%2Crelated_fqdns%2Cgroups',
    permissionOpen: true,
    active: false,
    showSummary: false,
    columnPickerPath: PathNames.unlinkedAssetsColumns,
    gridPath: PathNames.unlinkedAssets,
    tabPath: PathNames.unlinkedAssetsSummary,
    count: 0,
    featureFlag: 'isUnlinkedAssets',
    savedViews: true
  },
  mobileAssets: {
    title: 'Mobile Apps',
    key: 'mobile_assets',
    IconComponent: null,
    link: '/pages/discovery/mobileAssets',
    defaultView:
      '/pages/discovery/mobileAssets?fields=id%2Crisk_score%2Casset%2Capplication%2Cversion%2Ctype%2Cstore_url%2Cfirst_seen%2Cgroups',
    permissionOpen: true,
    active: false,
    showSummary: false,
    columnPickerPath: PathNames.mobileAssetsColumns,
    gridPath: PathNames.mobileAssets,
    tabPath: PathNames.mobileAssetsSummary,
    count: 0,
    featureFlag: 'isMobile',
    savedViews: true
  }
};

export const TAGS_OPTIONS: IActionOption[] = [
  {
    IconComponent: IconAddTag,
    label: 'Add Tags',
    ff: 'isCustomTags'
  },
  {
    IconComponent: IconRemoveTag,
    label: 'Remove Tags',
    ff: 'isCustomTags'
  },
]

export const DISCOVERY_EVIDENCE_OPTIONS: IActionOption[] = [
  {
    IconComponent: IconExportCSV,
    label: 'Export Discovery Evidence'
  }
];

export const ACTIONS_ELEMENT_SELECT: Record<string, IActionSelect> = {
  internalAssets: {
    header: 'Actions',
    options: [...TAGS_OPTIONS, ...DISCOVERY_EVIDENCE_OPTIONS],
    apiPath: 'discovery/org-assets/'
  },
  loginAssets: {
    header: 'Actions',
    options: TAGS_OPTIONS,
    apiPath: 'discovery/logins/'
  }
}
