import { StyledDataGridPro } from 'common/components/grid/Grid';
import './ClusterGridTable.scss';
import { ManageNotData } from 'common/components/manageNotData/ManageNotData';
import { useEffect, useState } from 'react';
import { queryBuilder } from 'common/components/grid/query-builder.service';
import { RestApi } from 'common/services/rest-api.service';
import { Common } from 'common/services/common.service';
import { AppLoading } from 'common/components/appLoading/appLoading';
import classNames from 'classnames';
import { AppDispatch } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { addRowsToCluster, emptyClusterTable } from 'features/GridSlice';

export function ClusterGridTable(props: any) {
  const [count, setCount] = useState<number>(0);
  const [next, setNext] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const { clusters, filters, initialPath, search, fields: gridFields } = useSelector((state: any) => state.grid);
  const { globalFilter } = useSelector((state: any) => state.general);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    queryBuilder.setBaseURL(initialPath);
    queryBuilder.buildQuery({ filters: filters, search: search, order: '', fields: gridFields }, globalFilter); // <- HERE!!!
    let query = queryBuilder.getQuery();
    const url = new URL(query);
    const fields = url.searchParams.get('fields')?.split(',');
    if (!fields?.includes('id') && fields) {
      fields?.push('id');
      url.searchParams.set('fields', fields?.join());
    }
    fetch(url.href);
    return () => {
      dispatch(emptyClusterTable(props.data.cluster_name));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addFilter = () => {
    if (!props.data.is_original_cluster) {
      return `&${props.data.replaced_cluster}=${props.data.cluster_name}`;
    }
    if (props.type === 'asset_importance' && props.data.cluster_name === null) {
      return `&has_asset_importance=false`;
    }
    if (props.type === 'apex_domain') {
      if (props.data.cluster_name === null) {
        return '';
      } else {
        return `&apex_domain=${props.data.cluster_name}`;
      }
    }
    return `&${props.type}${
      ['asset', 'title', 'handle_together_group', 'primary_ai'].includes(props.type) ? '' : '__in'
    }=${props.data.cluster_name}`;
  };

  const fetch = (query: string) => {
    RestApi.getData(Common.splitV1FromPath(query) + addFilter()).subscribe((response: any) => {
      dispatch(addRowsToCluster({ clusterId: props.data.cluster_name, rows: response.results }));
      setNext(response.next);
      setCount(Math.min(response.count, 5));
      setLoading(false);
    });
  };

  const handleOnRowsScrollEnd = () => {
    if (next) {
      fetch(next);
    }
  };

  if (!clusters[props.data.cluster_name] || !clusters[props.data.cluster_name].length || loading) {
    return (
      <div className={classNames('ClusterGridTable')}>
        <AppLoading />
      </div>
    );
  }

  return (
    <div className={classNames('ClusterGridTable', `count-${count}`)}>
      <StyledDataGridPro
        components={{
          NoRowsOverlay: () => <ManageNotData />
        }}
        filterMode="server"
        sortingMode="server"
        disableMultipleColumnsSorting={true}
        disableSelectionOnClick={true}
        disableColumnReorder={true}
        checkboxSelection
        disableColumnMenu={true}
        hideFooter={true}
        columns={props.columns.map((c: any) => {
          c.sortable = false;
          return c;
        })}
        onSelectionModelChange={props.onSelection}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        rows={clusters[props.data.cluster_name]}
        scrollEndThreshold={220}
      />
    </div>
  );
}
