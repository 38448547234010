// import React, { useState, useMemo } from 'react';
import { useTopPanelContext } from '../../TopPanelContext';
import { Text, DataPanel, Chart, IconTrend, TOOLTIP_TYPE } from '@cyberpion/cyberpion-ui';
import { Grid } from '@mui/material';
import { textProps, dataPanelProps, chartOptions } from '../TopPanelDiscovery';
import { TRENDS_COLORS, getIsTrendUp, getChartSeries, getChartCategories } from 'common/components/TopPanel/utils';

const DiscoveryLoginExpand = () => {
  const { data } = useTopPanelContext();
  const { insecure_login, invalid_certificates } = data;
  const { over_http, data: insecureChartData } = insecure_login;
  const { data: invalidChartData } = invalid_certificates;

  let updatedInvalidChartOptions;
  let updatedInsecureChartOptions;
  let insecureColors;
  let invalidColors;
  let isTrendUpInvalid;
  let isTrendUpInsecure;
  let last30DaysInsecure;
  let last30DaysInsecureProps;
  let last30DaysInvalid;
  let last30DaysInvalidProps;

  const updatedInsecureSeries = getChartSeries(insecureChartData, true);
  const updatedInvalidSeries = getChartSeries(invalidChartData, true);

  if (!!Object.keys(updatedInsecureSeries).length) {
    updatedInsecureChartOptions = {
      ...chartOptions,
      xAxis: {
        ...chartOptions.xAxis,
        categories: getChartCategories(insecureChartData, 'm')
      }
    };
    isTrendUpInsecure = getIsTrendUp(updatedInsecureSeries);
    insecureColors = isTrendUpInsecure ? TRENDS_COLORS.BAD : TRENDS_COLORS.GOOD;
    last30DaysInsecure =
      !!updatedInsecureSeries && updatedInsecureSeries.length > 1
        ? updatedInsecureSeries[updatedInsecureSeries.length - 1].y -
          updatedInsecureSeries[updatedInsecureSeries.length - 2].y
        : 0;

    last30DaysInsecureProps =
      last30DaysInsecure !== 0
        ? {
            isTrend: true,
            isTrendUp: isTrendUpInsecure,
            rightContentClassName: 'last-30',
            isUpArrowGreen: false
          }
        : {};
  }

  if (!!Object.keys(updatedInvalidSeries).length) {
    updatedInvalidChartOptions = {
      ...chartOptions,
      xAxis: {
        ...chartOptions.xAxis,
        categories: getChartCategories(invalidChartData, 'm')
      }
    };
    isTrendUpInvalid = getIsTrendUp(updatedInvalidSeries);
    invalidColors = isTrendUpInvalid ? TRENDS_COLORS.BAD : TRENDS_COLORS.GOOD;
    last30DaysInvalid =
      !!updatedInvalidSeries && updatedInvalidSeries.length > 1
        ? updatedInvalidSeries[updatedInvalidSeries.length - 1].y -
          updatedInvalidSeries[updatedInvalidSeries.length - 2].y
        : 0;

    last30DaysInvalidProps =
      last30DaysInvalid !== 0
        ? {
            isTrend: true,
            isTrendUp: isTrendUpInvalid,
            rightContentClassName: 'last-30',
            isUpArrowGreen: false
          }
        : {};
  }

  return (
    <Grid container className="collapse-panel-discovery-loginAssets" sx={{ paddingLeft: 2.5 }}>
      <Grid item sx={{ flex: 1 }}>
        <Grid container direction="column" className="insecure-wrapper" sx={{ marginTop: 2.5 }}>
          <Grid item className="panel-title">
            <Text textColor="#FFFFFF" textSize={13} upper>
              INSECURE LOGIN PAGES
            </Text>
          </Grid>
          <Grid item>
            <Grid container className="data-panel-wrapper">
              <Grid item className="insecure-data-panel">
                <DataPanel
                  id="over_http"
                  {...dataPanelProps}
                  titleStyle={{ color: '#A6acbe', paddingBottom: 0 }}
                  textSize={36}
                  title={'Accessible over HTTP'}
                >
                  {over_http}
                </DataPanel>
              </Grid>
              <Grid item className="insecure-chart chart-size" sx={{ flex: 1, maxWidth: 550 }}>
                <Chart
                  chartOptions={updatedInsecureChartOptions}
                  chartType="area"
                  colors={insecureColors}
                  series={updatedInsecureSeries}
                  tooltipType={TOOLTIP_TYPE.LAST_SCAN_ADDED}
                  isTooltipPointValueGreen={!isTrendUpInsecure}
                  isTooltipUpArrowGreen={false}
                  // isLogarithmic
                />
              </Grid>
              <Grid item className="login-last-30" sx={{ width: 90 }}>
                <DataPanel
                  id="overall"
                  {...dataPanelProps}
                  textSize={36}
                  titleStyle={{ color: '#A6acbe', paddingBottom: 0 }}
                  title="Last 30 Days"
                  {...last30DaysInsecureProps}
                >
                  {last30DaysInsecure}
                </DataPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <div className="vertical-devider" style={{ flex: 0 }}></div>
      </Grid>
      <Grid item sx={{ flex: 1 }}>
        <Grid container direction="column" sx={{ marginTop: 2.5 }}>
          <Grid item className="panel-title">
            <Text textColor="#FFFFFF" upper textSize={13}>
              INVALID CERTIFICATES
            </Text>
          </Grid>
          <Grid item>
            <Grid container className="horizontal-border data-panel-wrapper">
              <Grid item className="invalid-data-panel" sx={{ flexBasis: 120 }}>
                <DataPanel
                  id="valid_certificate"
                  {...dataPanelProps}
                  titleStyle={{ color: '#A6acbe', paddingBottom: 0 }}
                  textSize={36}
                  title={'Currently Insecure'}
                >
                  {updatedInvalidSeries[updatedInvalidSeries.length - 1].y}
                </DataPanel>
              </Grid>
              <Grid item className="invalid-chart chart-size" sx={{ flex: 1, maxWidth: 550 }}>
                <Chart
                  chartOptions={updatedInvalidChartOptions}
                  chartType="area"
                  colors={invalidColors}
                  series={updatedInvalidSeries}
                  tooltipType={TOOLTIP_TYPE.LAST_SCAN_ADDED}
                  isTooltipPointValueGreen={!isTrendUpInvalid}
                  isTooltipUpArrowGreen={false}
                  // isLogarithmic
                />
              </Grid>
              <Grid item className="login-last-30" sx={{ width: 90 }}>
                <DataPanel
                  id="overall"
                  {...dataPanelProps}
                  textSize={36}
                  title="Last 30 Days"
                  titleStyle={{ color: '#A6acbe', paddingBottom: 0 }}
                  {...last30DaysInvalidProps}
                >
                  {last30DaysInvalid}
                </DataPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const DiscoveryLoginCollapse = () => {
  const { data } = useTopPanelContext();
  const { insecure_login, invalid_certificates } = data;
  const { over_http, data: insecureChartData } = insecure_login;
  const { currently_insecure, data: invalidChartData } = invalid_certificates;

  const updatedInsecureSeries = getChartSeries(insecureChartData, true);
  const isTrendUpInsecure = getIsTrendUp(updatedInsecureSeries) || false;
  const last30DaysInsecure =
    !!updatedInsecureSeries && updatedInsecureSeries.length > 1
      ? updatedInsecureSeries[updatedInsecureSeries.length - 1].y -
        updatedInsecureSeries[updatedInsecureSeries.length - 2].y
      : 0;

  const updatedInvalidSeries = getChartSeries(invalidChartData, true);
  const isTrendUpInvalid = getIsTrendUp(updatedInvalidSeries) || false;
  const last30DaysInvalid =
    !!updatedInvalidSeries && updatedInvalidSeries.length > 1
      ? updatedInvalidSeries[updatedInvalidSeries.length - 1].y -
        updatedInvalidSeries[updatedInvalidSeries.length - 2].y
      : 0;

  return (
    <Grid container className="top-panel-discovery-login-collapse-wrapper">
      <Grid item className="top-panel-login-title">
        <Text {...textProps}>INSECURE LOGIN PAGES</Text>
      </Grid>

      <Grid item className="login-title">
        <Text {...textProps} upper={false} textSize={11}>
          Accessible Over Http
        </Text>
      </Grid>
      <Grid item className="login-value">
        <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
          {over_http}
        </Text>
      </Grid>
      <Grid item className="login-title">
        <Text {...textProps} upper={false} textSize={11}>
          Last 30 Days
        </Text>
      </Grid>
      <Grid item className="login-value with-svg">
        <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
          {last30DaysInsecure}
        </Text>
        {last30DaysInsecure !== 0 && <IconTrend isTrendUp={isTrendUpInsecure} isUpArrowGreen={!isTrendUpInsecure} />}
      </Grid>

      <div className="vertical-devider-collapse"></div>
      <Grid item className="top-panel-login-title-second">
        <Text {...textProps}> INVALID CERTIFICATES</Text>
      </Grid>

      <Grid item className="login-title">
        <Text {...textProps} upper={false} textSize={11}>
          Currently Insecure
        </Text>
      </Grid>
      <Grid item className="login-value">
        <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
          {currently_insecure}
        </Text>
      </Grid>
      <Grid item className="login-title">
        <Text {...textProps} upper={false} textSize={11}>
          Last 30 Days
        </Text>
      </Grid>
      <Grid item className="login-value with-svg">
        <Text {...textProps} textSize={16} weight={600} family="Rajdhani">
          {last30DaysInvalid}
        </Text>
        {last30DaysInvalid !== 0 && <IconTrend isTrendUp={isTrendUpInvalid} isUpArrowGreen={!isTrendUpInvalid} />}
      </Grid>
    </Grid>
  );
};

export { DiscoveryLoginExpand, DiscoveryLoginCollapse };
