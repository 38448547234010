// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const DiscoveryToolSlice = createSlice({
  name: 'discoveryTool',
  initialState: {
    shouldMenuActionAffectAllRows: false
  },
  reducers: {
    setShouldMenuActionAffectAllRows(state, { payload }) {
      state.shouldMenuActionAffectAllRows = payload;
    }
  }
});

const { actions, reducer } = DiscoveryToolSlice;
export const { setShouldMenuActionAffectAllRows } = actions;
export default reducer;
