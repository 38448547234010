import React, { useEffect } from 'react';
import { Grid } from 'common/components/grid/Grid';
import { RestApi } from 'common/services/rest-api.service';
import { IRootColumnPicker } from 'common/interfaces/interface';
import { Paths } from 'common/constants/paths';
import { ColumnsManage } from 'common/components/grid/columnsManage';
import { TABSET, ACTIONS_ELEMENT_SELECT } from './config';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { QuickViewPanel } from 'common/components/quickViewPanel/QuickViewPanel';
import { AppDispatch, IReduxState } from 'app/store';
import { removeAllRows, resetQueryString, setFields, setInitialPath } from 'features/GridSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PopUp } from 'common/components/popUp/PopUp';
import { UnlinkedAssets } from 'common/components/popUp/components/UnlinkedAssets/UnlinkedAssets';
import { NotFound } from 'notFound/NotFound';
import useShowToggle from 'common/hooks/useShowToggle';
import TopPanel from 'common/components/TopPanel/TopPanel';
import TOP_PANEL_PAGES from 'common/constants/topPanelPages';
import { Grid as MUIGrid } from '@mui/material';
import { TopPanelLoadingState } from 'common/components/TopPanel/TopPanelLoadingState/TopPanelLoadingState';
import { setColumnPicker, setShowColumnPicker } from 'features/columnPickerSlice';
import { getAggregateData, setTopPanelContext, setTopPanelInitialPath } from 'features/TopPanelSlice';
import classNames from 'classnames';

import '../pages.scss';

export interface IInventory {
  rows?: any;
  path?: string;
  isMoreButton: boolean;
  isCSVDownloadable: boolean;
  count: number;
  columns: Array<any>;
  rootPath: string;
  identifier: string;
}

export function InventoryRouting(props: any) {
  const [showGrid, setShowGrid] = React.useState<boolean>(false);
  const [dataQuickViewPanel, setDataQuickViewPanel] = React.useState<any>();
  const [showQuickViewPanel, setShowQuickViewPanel] = React.useState<boolean>(false);
  const [unlinkedAssetsData, setUnlinkedAssetsData] = React.useState<{}>({});
  const [inventoryData, setInventoryData] = React.useState<IInventory>(initData());
  const [selectTab, setSelectTab] = React.useState<string>(props.selectTab);
  const [showUnlinkedAssets, setShowUnlinkedAssets] = React.useState<boolean>(false);
  const [noPermission, setNoPermission] = React.useState<boolean>(false);
  const [abortControllerGrid, setAbortControllerGrid] = React.useState<AbortController>();
  const { isOpen: isTopPanelOpen, setToggle: setTopPanelToggle } = useShowToggle(true);
  const { globalFilter } = useSelector((state: IReduxState) => state.general);
  const { filters } = useSelector((state: IReduxState) => state.grid);
  const { topPanelData } = useSelector((state: IReduxState) => state.topPanel);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    fetchGridData(props.selectTab);
    if (props.selectTab !== selectTab) {
      dispatch(removeAllRows());
      dispatch(resetQueryString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectTab]);

  useEffect(() => {
    if (TABSET[props.selectTab].showSummary) {
      const time = setTimeout(() => {
        dispatch(setTopPanelContext(props.selectTab));
        dispatch(setTopPanelInitialPath(Paths[TABSET[props.selectTab].tabPath]));
        dispatch(getAggregateData());
      }, 1500);

      return () => {
        clearTimeout(time);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter, filters]);

  useEffect(() => {
    dispatch(resetQueryString());
    return () => {
      dispatch(removeAllRows());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowQuickViewPanel(false);
  }, [selectTab]);

  function initData(): IInventory {
    return {
      rows: [],
      isMoreButton: true,
      isCSVDownloadable: false,
      count: 0,
      columns: [],
      rootPath: '',
      identifier: ''
    };
  }

  function fetchGridData(tab: string) {
    setSelectTab(props.selectTab);
    setShowGrid(false);
    setNoPermission(false);
    if (abortControllerGrid) {
      abortControllerGrid.abort();
    }
    const abort_controller = new AbortController();
    setAbortControllerGrid(abort_controller);
    RestApi.getData(Paths[TABSET[tab].columnPickerPath], true, abort_controller.signal).subscribe(
      (columns: IRootColumnPicker) => {
        let fields = ColumnsManage.checkGridColumnsToDisplay(columns.data);
        dispatch(setFields(fields));
        dispatch(setInitialPath(Paths[TABSET[tab].gridPath]));
        dispatch(setColumnPicker(columns.data));
        dispatch(setShowColumnPicker(columns.is_displayable));

        setInventoryData({
          rows: [],
          isMoreButton: columns.is_more_button,
          isCSVDownloadable: columns.is_csv_downloadable,
          count: 0,
          identifier: columns.identifier_field,
          columns: ColumnsManage.createColumns(columns.identifier_field, columns.data || [], {
            page: 'discovery',
            tab: props.selectTab
          }),
          rootPath: Paths[TABSET[tab].gridPath]
        });
        setShowGrid(true);
      },
      err => {
        if (err.status === 403) {
          setNoPermission(true);
        }
      }
    );
  }

  function onRowDoubleClick(params: GridRowParams) {
    if (selectTab === 'digitalSupplyChain' || selectTab === 'internalAssets') {
      RestApi.getData(`${inventoryData.rootPath}${params.row[inventoryData.identifier]}/`).subscribe(
        (response: any) => {
          setDataQuickViewPanel(response);
          setShowQuickViewPanel(true);
        }
      );
    } else if (selectTab === 'unlinkedAssets') {
      setUnlinkedAssetsData({
        host: params.row.asset
      });
      setShowUnlinkedAssets(true);
    }
  }

  const getElementSelect = () => {
    if (ACTIONS_ELEMENT_SELECT?.[selectTab]) {
      return ACTIONS_ELEMENT_SELECT[selectTab]
    }
    return undefined;
  };

  if (noPermission) {
    return <NotFound type="403" />;
  }

  const showTopPanel =
    !!topPanelData &&
    ((props.selectTab === 'internalAssets' && topPanelData.context === 'internalAssets') ||
      (props.selectTab === 'digitalSupplyChain' && topPanelData.context === 'digitalSupplyChain') ||
      (props.selectTab === 'loginAssets' && topPanelData.context === 'loginAssets'));

  return (
    <>
      {!!TABSET[props.selectTab] &&
        TABSET[props.selectTab].showSummary &&
        (showTopPanel ? (
          <TopPanel
            page={TOP_PANEL_PAGES.DISCOVERY}
            tab={props.selectTab}
            data={topPanelData}
            isOpen={isTopPanelOpen}
            setToggle={setTopPanelToggle}
          />
        ) : (
          <div style={{ position: 'sticky', height: isTopPanelOpen ? '155px' : '60px' }}>
            <MUIGrid container direction="column">
              <MUIGrid
                item
                className={classNames('top-panel-loading-state', { open: isTopPanelOpen }, { closed: !isTopPanelOpen })}
              >
                <TopPanelLoadingState page={TOP_PANEL_PAGES.DISCOVERY} isOpen={true} />
              </MUIGrid>
              <MUIGrid item sx={{ height: 10 }}></MUIGrid>
            </MUIGrid>
          </div>
        ))}

      {showGrid ? (
        <>
          <div style={{ height: '100%', zIndex: 1 }}>
            <Grid
              elementSelect={getElementSelect()}
              onRowDoubleClick={onRowDoubleClick}
              columns={inventoryData.columns}
              rows={inventoryData.rows}
              count={inventoryData.count}
              path={inventoryData.path}
              isMoreButton={inventoryData.isMoreButton}
              isCSVDownloadable={inventoryData.isCSVDownloadable}
              identifier={inventoryData.identifier}
              pageTab={TABSET[props.selectTab].gridPath}
              page={props.source}
              savedViews={TABSET[props.selectTab].savedViews}
            />
          </div>
          {showQuickViewPanel && (
            <QuickViewPanel
              setVisible={setShowQuickViewPanel}
              visible={showQuickViewPanel}
              nameTab={selectTab}
              data={dataQuickViewPanel}
              identifier={inventoryData.identifier}
            />
          )}
          {showUnlinkedAssets && (
            <PopUp setVisible={setShowUnlinkedAssets} visible={showUnlinkedAssets}>
              <UnlinkedAssets setVisible={setShowUnlinkedAssets} data={unlinkedAssetsData} />
            </PopUp>
          )}
        </>
      ) : (
        <div className="grid-loading">Loading...</div>
      )}
    </>
  );
}
