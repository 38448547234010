import React, { useEffect, useRef, useState } from 'react';
import { TechnicalDetails } from 'common/components/TechnicalDetails/TechnicalDetails';
import { Text } from '@cyberpion/cyberpion-ui';
import { Common } from 'common/services/common.service';
import Tooltip from '@mui/material/Tooltip';
import { IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { AddRemoveTags } from 'common/components/AddRemoveTags/AddRemoveTags';
import { RestApi } from 'common/services/rest-api.service';
import { updateRows } from 'features/GridSlice';

const linkIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M7.5249 2.5H10.0749V5.05" stroke="#3659DE" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.075 2.5L6.25 6.325" stroke="#3659DE" stroke-linecap="round" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.8248 2H3.4248C2.59638 2 1.9248 2.67157 1.9248 3.5V9.15C1.9248 9.97843 2.59638 10.65 3.42481 10.65H9.0748C9.90323 10.65 10.5748 9.97843 10.5748 9.15V6.75H9.5748V9.15C9.5748 9.42614 9.35095 9.65 9.0748 9.65H3.42481C3.14866 9.65 2.9248 9.42614 2.9248 9.15V3.5C2.9248 3.22386 3.14866 3 3.4248 3H5.8248V2Z"
      fill="#3659DE"
    />
  </svg>
);

const plusIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M10.2886 13.6348L10.2886 6.21143" stroke="#3455DD" stroke-linecap="round" />
    <path d="M6.57666 9.92383L14 9.92383" stroke="#3455DD" stroke-linecap="round" />
  </svg>
);

const PopSideDetailsTab = ({ data }: { data: any }) => {
  const { rows } = useSelector((state: IReduxState) => state.grid);
  const [showTags, setShowTags] = useState(false);
  const [tags, setTags] = useState(data.tags || []);
  const [addIconClassName, setAddIconClassName] = useState('');
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const dispatch = useDispatch<any>();
  const refAddIcon = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!refAddIcon?.current) {
      const top = refAddIcon.current.getBoundingClientRect().top;
      setAddIconClassName(top > 480 ? 'top-position' : 'bottom-position');
    }
  }, [refAddIcon]);

  const rawRequest =
    data?.technical_details &&
    Object.keys(data.technical_details).filter((key: string) => key.toLowerCase().startsWith('raw')).length > 0
      ? Object.keys(data.technical_details).filter((key: string) => key.toLowerCase().startsWith('raw'))
      : [];

  const handleReferenceOnClick = (link: string) => () => {
    window.open(Common.addGlobalFilterIfNeeded(link), '_blank');
  };

  const handleScreenshotOnClick = (ev: any) => {
    ev.preventDefault();
    const _window = window.open();
    const iframe = `<iframe style='border: 0;' width='100%' height='100%' src='${data.technical_details.screenshot}'></iframe>`;
    if (_window) {
      _window.document.open();
      _window.document.write(iframe);
      _window.document.close();
    }
  };

  const onDeleteTag = (tag: string) => {
    RestApi.setData('remediation/action-items/tags/', { ids: [data.id], tags: [tag] }, 'DELETE').subscribe(
      (response: any) => {
        const updatedTags = tags.filter((t: any) => t.name !== tag);
        setTags(updatedTags);
        const row = rows.find((row: any) => row.id === data.id);
        const updatedRow = [
          {
            ...row,
            tags: updatedTags
          }
        ];
        dispatch(updateRows({ rows: updatedRow, identifier: 'id' }));
      },
      error => {}
    );
  };

  const handleOnAddTags = (addedTags: string[]) => {
    const newTags = addedTags.filter((added: string) => !tags.map((t: any) => t.name).includes(added));
    const updatedTags = newTags.map((t: any) => ({
      name: t,
      source: 'custom'
    }));
    setTags([...tags, ...updatedTags]);

    const row = rows.find((row: any) => row.id === data.id);
    const updatedRow = [
      {
        ...row,
        tags: [...tags, ...updatedTags]
      }
    ];
    dispatch(updateRows({ rows: updatedRow, identifier: 'id' }));
  };

  const allTags = columnPicker.filter((c: any) => c.name === 'tags')[0];

  return (
    <div className="PopSideDetailsTab">
      {data.summary && (
        <div className="data-item">
          <Text weight={500} textSize={13} style={{ paddingBottom: 6 }}>
            Summary
          </Text>
          <Text textSize={14} textColor="#5D6576">
            {data.summary}
          </Text>
        </div>
      )}

      {data.description && (
        <div className="data-item">
          <Text weight={500} textSize={13} style={{ paddingBottom: 6 }}>
            Description
          </Text>
          <Text textSize={14} textColor="#5D6576">
            {data.description}
          </Text>
        </div>
      )}

      {data.references && (
        <div className="data-item">
          <Text weight={500} textSize={13} style={{ paddingBottom: 6 }}>
            References
          </Text>
          <div className="references-list">
            {Object.entries(data.references).map(([key, value]: any, index): any => (
              <Tooltip
                title={key}
                placement="bottom"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#4D4C53',
                      color: '#FFFFFF',
                      padding: '3px 6px',
                      '& .MuiTooltip-arrow': {
                        color: '#4D4C53'
                      },
                      border: '2px solid black',
                      borderRadius: 1
                    }
                  }
                }}
              >
                <div className="references-item" onClick={handleReferenceOnClick(value)} key={index}>
                  <Text
                    textSize={13}
                    textColor="#3455DD"
                    style={{
                      marginLeft: 5,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: 240,
                      paddingRight: 5
                    }}
                  >
                    {key}
                  </Text>
                  {linkIcon}
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      )}

      {data.impact && (
        <div className="data-item">
          <Text weight={500} textSize={13} style={{ paddingBottom: 6 }}>
            Impact
          </Text>
          <Text textSize={14} textColor="#5D6576">
            {data.impact}
          </Text>
        </div>
      )}

      {!!data.groups?.length && (
        <div className="data-item">
          <Text weight={500} textSize={13} style={{ paddingBottom: 6 }}>
            Groups
          </Text>
          <div className="groups-list">
            {data.groups.map((group: any, index: number) => (
              <div key={index} className="group-item">
                <Text textSize={14} textColor="#5D6576">
                  {group}
                </Text>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* {!!tags?.length && ( */}
      <div className="data-item">
        <div className="add-tags-action">
          {(!data.last_closed_at || !!tags?.length) && (
            <Text weight={500} textSize={13}>
              Tags
            </Text>
          )}
          {!!tags?.length && !!allTags?.choices?.length && !data.last_closed_at && (
            <div className="plus-wrapper" onClick={() => setShowTags(true)}>
              <div ref={refAddIcon} className="plus-icon">
                {plusIcon}
              </div>
              {showTags && (
                <div className={`add-tags ${addIconClassName}`}>
                  <AddRemoveTags
                    method={'add'}
                    tags={allTags}
                    ids={[data.id]}
                    onClose={() => setShowTags(false)}
                    apiPath="remediation/action-items/"
                    identifier="id"
                    loadedRows={[data]}
                    onSubmitted={handleOnAddTags}
                  />
                </div>
              )}
            </div>
          )}
          {!tags?.length && !!allTags?.choices?.length && !data.last_closed_at && (
            <div ref={refAddIcon} className="btn-add-tag" onClick={() => setShowTags(true)}>
              <Text textSize={13} textColor="#3355DD">
                Add Tag
              </Text>
              {showTags && (
                <div className={`add-tags ${addIconClassName}`}>
                  <AddRemoveTags
                    method={'add'}
                    tags={allTags}
                    ids={[data.id]}
                    onClose={() => setShowTags(false)}
                    apiPath="remediation/action-items/"
                    identifier="id"
                    loadedRows={[data]}
                    onSubmitted={handleOnAddTags}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="details-chips-list">
          {!!tags?.length &&
            tags.map((tag: any, index: number) => {
              const isCustom =
                allTags?.choices.filter(
                  (t: any) => t.name?.toLowerCase() === tag?.name?.toLowerCase() && t.source?.toLowerCase() === 'custom'
                ).length > 0 || tag.source?.toLowerCase() === 'custom';
              return (
                <div className="tag-item">
                  <Text
                    key={index}
                    textSize={13}
                    textColor="#5D6576"
                    style={{
                      paddingLeft: 6,
                      paddingRight: 6,
                      borderRight: '1px solid #dfe1e4',
                      height: 22,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {tag.name}
                  </Text>

                  {isCustom && (
                    <span className="delete-tag" onClick={(e: any) => onDeleteTag(tag.name)}>
                      <img src="/assets/images/x.svg" alt="Delete" />
                    </span>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      {/* )} */}

      {!!data.technical_details && (
        <div className="technical-details-container">
          <Text weight={500} textSize={13} style={{ paddingBottom: 6 }}>
            Technical Details
          </Text>
          <TechnicalDetails data={data.technical_details} isActionItemsPDF={true} showTechnicalDetailsOnly />
        </div>
      )}

      {!!rawRequest &&
        !!rawRequest.length &&
        rawRequest.map((raw: any, index: number) => (
          <div key={index} style={{ padding: '0 20px 20px 20px' }}>
            <Text weight={500} textSize={13} style={{ paddingBottom: 6 }}>
              {raw}
            </Text>
            <div className="technical-container">
              {/* <Text textColor="#807F80" family="monospace" style={{ lineHeight: 1.4 }}>
                {data.technical_details[raw]}
              </Text> */}
              <textarea readOnly>{data.technical_details[raw]}</textarea>
            </div>
          </div>
        ))}

      {data && data?.technical_details && data.technical_details?.screenshot && (
        <div className="data-item" style={{ marginBottom: 20, width: 445 }}>
          <div className="screenshot" style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Text weight={500} textSize={13} style={{ paddingBottom: 6 }}>
                Screenshot
              </Text>
              {/* {linkIcon} */}
              <div
                className="screenshot-icon"
                style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}
                onClick={handleScreenshotOnClick}
              >
                {linkIcon}
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <img style={{ width: '100%', height: 240 }} src={data.technical_details.screenshot} alt="icon" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopSideDetailsTab;
