import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconMenu } from '../Icons/IconMenu/IconMenu';
import { Common } from '../../services/common.service';
import { RestApi } from '../../services/rest-api.service';
import { getRows } from '../../../features/GridSlice';
import { useDispatch } from 'react-redux';
import { showSuccess, showError } from 'features/AlertSlice';
import { AppLoading } from '../appLoading/appLoading';
import './ParkedDomainsActionsMenu.scss';

interface IParkedDomainsActionMenu {
  rowData: any;
}

export default function ParkedDomainsActionMenu(props: IParkedDomainsActionMenu) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch<any>();

  const isDisabled = props.rowData.row.parked_domain_source === 'Discovery';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateParkedDomainAsset = ({ is_parked_domain }: { is_parked_domain: boolean | null }) => {
    setLoading(true);
    const url = Common.AddMsspAccountIfNeeded('settings/organization-management/parked-domains/');
    const payload = {
      assets: [props.rowData.row.asset],
      is_parked_domain: is_parked_domain,
      parked_domain_source: 'Manual'
    };
    RestApi.setData(url, payload, 'POST').subscribe(
      (response: any) => {
        setLoading(false);
        handleClose();
        dispatch(getRows());
        dispatch(showSuccess('Updated Successfully'))
      },
      (error: any) => {
        setLoading(false);
        dispatch(showError('Failed to change asset status'))
      }
    );
  }

  const setAsActive = () => {
    updateParkedDomainAsset({is_parked_domain: false})
  };

  const resetToDefault = () => {
    updateParkedDomainAsset({is_parked_domain: null})
  };

  return (
    <div className="ParkedDomainsActionMenu">
      <Button
        className="ParkedDomainsActionMenuButton"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {loading && <AppLoading />}
        <IconMenu active={false} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled={loading} onClick={setAsActive}>Set As Active</MenuItem>
        <MenuItem disabled={loading || isDisabled} onClick={resetToDefault}>Reset To Default</MenuItem>
      </Menu>
    </div>
  );
}