import { RestApi } from './rest-api.service';
import { store } from '../../app/store';

type EventType =
  | 'NAVIGATION'
  | 'LOGIN'
  | 'SAVE_VIEWS'
  | 'ADD_COLUMN'
  | 'REMOVE_COLUMN'
  | 'ADD_FILTER'
  | 'REMOVE_FILTER';

export const Amplitude = {
  save(eventType: EventType, data: any) {
    const user = store.getState().login.user as { is_mssp_user: boolean };
    if (user?.is_mssp_user) {
      return;
    }
    const payload = {
      event_type: eventType,
      event_data: data,
      source: 'FE'
    };
    const url = 'product-analytics/';
    RestApi.setData(url, payload, 'POST').subscribe(
      (response: any) => {},
      error => {}
    );
  }
};
