import { PopupHeader } from '../../../../../common/components/PopupHeader/PopupHeader';
import classNames from 'classnames';
import Checkbox from '@mui/material/Checkbox';
import { Search } from '../../../../../common/components/Search/Search';
import { useEffect, useState } from 'react';
import { FormControlLabel, List, ListItem, ListItemText, Divider } from '@mui/material';
import { RestApi } from '../../../../../common/services/rest-api.service';
import { Common } from '../../../../../common/services/common.service';
import { getRows } from '../../../../../features/GridSlice';
import { showError, showSuccess } from '../../../../../features/AlertSlice';
import { useDispatch } from 'react-redux';
import { AppLoading } from '../../../../../common/components/appLoading/appLoading';
import * as React from 'react';
import './AddParkedDomainsPopup.scss';

export function AddParkedDomainsPopup(props: any) {
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [assets, setAssets] = useState<any[]>([]);
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const fetchAssets = (value = '') => {
    if (abortController) {
      abortController.abort();
    }
    const controller = new AbortController();
    setAbortController(controller);
    const url = `discovery/org-assets/?is_parked_domain=false&fields=asset&search=${value}`;
    RestApi.getData(url, false, controller.signal).subscribe(
      (response: any) => {
        setAssets(response.results);
      },
      (error: any) => {
        if (error.name !== 'AbortError') {
          console.error("Request failed", error);
        }
      }
    );
  };
  // eslint-disable-next-line
  useEffect(fetchAssets, []);
  const dispatch = useDispatch<any>();

  const handleCheckboxChange = (item: string) => {
    setSelectedAssets((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((i) => i !== item);
      }
      return [...prevState, item];
    });
  };
  const submit = () => {
    setLoading(true);
    const url = Common.AddMsspAccountIfNeeded('settings/organization-management/parked-domains/');
    const payload = {
      assets: selectedAssets,
      is_parked_domain: true,
      parked_domain_source: 'Manual'
    };
    RestApi.setData(url, payload, 'POST').subscribe(
      (response: any) => {
        props.onClose()
        dispatch(getRows());
        dispatch(showSuccess('Updated Successfully'))
        setLoading(false);
      },
      (error: any) => {
        dispatch(showError('Failed to change asset status'))
        setLoading(false);
      }
    );
  }


  return (
    <div className="AddAssetsPopUp popup-inner-wrapper parkedDomains">
      <PopupHeader text="Add Additional Parked Domains" onClose={props.onClose} />
      <div className="popup-inner">
        <div className="message">
          <div>Use the below boxes to define assets that will be assigned to the group.</div>
        </div>
        <div>
          <Search onChange={fetchAssets} />
          <List
            sx={{
              height: 300,
              overflowY: 'auto',
            }}>
            {assets.map((item) => (
              <>
                <ListItem key={item.asset}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedAssets.includes(item.asset)}
                        onChange={() => handleCheckboxChange(item.asset)}
                        name={item.asset}
                      />
                    }
                    label={<ListItemText primary={item.asset} />}
                  />
                </ListItem>
                <Divider component="li" />
              </>
            ))}
          </List>
        </div>
        <div className="buttons-wrapper">
          <button className="btn btn-big btn-secondary" onClick={props.onClose}>
            Cancel
          </button>
          <button
            className={classNames('btn', 'btn-big', 'btn-main', { disabled: !selectedAssets.length })}
            onClick={submit}
          >
            Set As Parked
          </button>
        </div>
      </div>
      { loading && <AppLoading />}
    </div>
  );
}