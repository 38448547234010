// import { Button } from '@cyberpion/cyberpion-ui';
import { Text } from '@cyberpion/cyberpion-ui';
import './Header.scss';

const iconHeader = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M8 2L14 6V10L8 14L2 10V6L8 2Z" stroke="#3455DD" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 6L8 2L2 6V10L8 14L14 10V6ZM8.5 3.5C8.5 3.2239 8.2761 3 8 3C7.7238 3 7.5 3.2239 7.5 3.5V5.7324L5 7.3991L3.2773 6.2507C3.0476 6.0975 2.7371 6.1596 2.584 6.3893C2.4308 6.6191 2.4929 6.9295 2.7226 7.0827L4.7129 8.4095C4.7192 8.414 4.7256 8.4182 4.7321 8.4223L7.5 10.2676V12.5C7.5 12.7761 7.7238 13 8 13C8.2761 13 8.5 12.7761 8.5 12.5V10.2676L11.2679 8.4223C11.2744 8.4182 11.2808 8.414 11.2871 8.4095L13.2773 7.0827C13.5071 6.9295 13.5692 6.6191 13.416 6.3893C13.2628 6.1596 12.9524 6.0975 12.7226 6.2507L11 7.3991L8.5 5.7324V3.5ZM10.0986 8L8 6.6009L5.9014 8L8 9.3991L10.0986 8Z"
      fill="#3455DD"
    />
  </svg>
);
export function Header(props: any) {
  return (
    <header className="Discovery-Results-Header">
      <div className="title">
        {/* <img src={`/assets/images/Discovery.svg`} alt="Icon" /> */}
        <div style={{ border: '1px solid #ECEDFE', borderRadius: 4, marginRight: 8, padding: '3px 3px 1px 3px' }}>
          {iconHeader}
        </div>
        <Text textSize={18} weight={500}>
          Investigation Tool
        </Text>
      </div>
      <div>
        <Text textColor="#60646A" textSize={13} style={{ marginTop: 10 }}>
          Here you can select the relevant scan to analyze. The results are saved during your work. After the analysis
          is done, and in order to update the account - submit the report.
        </Text>
      </div>
    </header>
  );
}
