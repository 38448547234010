import { IItemFilterDate, IFilterGridOption } from 'common/components/grid/components/gridUtilities/interface';

export const FILTER_DATE: IItemFilterDate[] = [
  {
    id: 0,
    label: 'Last 24 Hours',
    value: null,
    error: false,
    is_toggle: false,
    show_data_picker: false,
    child: [],
    name: '__gte'
  },
  {
    id: 1,
    label: 'Last 7 Days',
    value: null,
    error: false,
    is_toggle: false,
    show_data_picker: false,
    child: [],
    name: '__gte'
  },
  {
    id: 2,
    label: 'Last 30 Days',
    value: null,
    error: false,
    is_toggle: false,
    show_data_picker: false,
    child: [],
    name: '__gte'
  },
  {
    id: 3,
    label: 'From',
    value: null,
    error: false,
    is_toggle: false,
    show_data_picker: true,
    child: [],
    name: '__gte'
  },
  {
    id: 4,
    label: 'Until',
    value: null,
    error: false,
    is_toggle: false,
    show_data_picker: true,
    child: [],
    name: '__lte'
  },
  {
    id: 5,
    label: 'Range',
    value: null,
    error: false,
    is_toggle: false,
    show_data_picker: false,
    name: '__range',
    child: [
      {
        id: 0,
        label: 'Between',
        value: null,
        error: false,
        show_data_picker: true
      },
      {
        id: 1,
        label: 'And',
        value: null,
        error: false,
        show_data_picker: true
      }
    ]
  }
];

export const NOTIFICATIONS_FILTER_STRING: IFilterGridOption[] = [
  {
    index: 0,
    label: 'Full Match',
    value: '',
    name: ''
  },
  {
    index: 1,
    label: 'Contains',
    value: '',
    name: '__contains'
  }
];

export const FILTER_NUMBER: IFilterGridOption[] = [
  {
    index: 0,
    label: 'Greater Than Equal',
    value: '',
    name: '__gte'
  },
  {
    index: 1,
    label: 'Less Than Equal',
    value: '',
    name: '__lte'
  }
];

export const FILTER_STRING: IFilterGridOption[] = [
  {
    index: 0,
    label: 'Contains',
    value: '',
    name: '__contains'
  },
  {
    index: 0,
    label: 'Not Contains',
    value: '',
    name: '__contains_ne'
  },
  {
    index: 1,
    label: 'Starts With',
    value: '',
    name: '__startswith'
  },
  {
    index: 2,
    label: 'Ends With',
    value: '',
    name: '__endswith'
  },
  {
    index: 3,
    label: 'Equals',
    value: '',
    name: '__intext'
  },
  {
    index: 4,
    label: 'Not Equals',
    value: '',
    name: '__ne'
  }
];

export const FILTER_IP: IFilterGridOption[] = [
  {
    index: 0,
    label: 'CIDR Contains',
    value: '',
    name: '__cidr_contains'
  },
  {
    index: 1,
    label: 'Starts With',
    value: '',
    name: '__startswith'
  },
  {
    index: 2,
    label: 'Exact Match',
    value: '',
    name: ''
  },
  {
    index: 3,
    label: 'Contained By',
    value: '',
    name: '__cidr_contained_by'
  }
];

export const FILTER_ARRAY_IP: IFilterGridOption[] = [
  {
    index: 0,
    label: 'Full Match',
    value: '',
    name: '__intext'
  },
  {
    index: 1,
    label: 'Not Contains',
    value: '',
    name: '__overlap_ne'
  },
  {
    index: 2,
    label: 'Contained By',
    value: '',
    name: '__cidr_contained_by_array'
  }
];

export const FILTER_ARRAY_STRING: IFilterGridOption[] = [
  {
    index: 0,
    label: 'Full Match',
    value: '',
    name: '__intext'
  },
  {
    index: 1,
    label: 'Contains',
    value: '',
    name: '__contains'
  },
  {
    index: 2,
    label: 'Not Contains',
    value: '',
    name: '__contains_ne'
  },
  {
    index: 3,
    label: '(Blank)',
    value: '',
    name: '__isnull=True'
  },
  {
    index: 4,
    label: 'Not (Blank)',
    value: '',
    name: '__isnull=False'
  }
];

export const FILTER_ARRAY_NUMBER: IFilterGridOption[] = [
  {
    index: 0,
    label: 'Full Match',
    value: '',
    name: '__overlap'
  },
  {
    index: 1,
    label: 'Not Contains',
    value: '',
    name: '__overlap_ne'
  },
  {
    index: 2,
    label: '(Blank)',
    value: '',
    name: '__isnull=True'
  },
  {
    index: 3,
    label: 'Not (Blank)',
    value: '',
    name: '__isnull=False'
  }
];

export const NON_SORTED_FIELDS = ['risk', 'importance', 'confidence_level', 'password_strength', 'urgency', 'asset_importance', 'risk_score', 'confidence_level', 'certainty', 'maintenance_grade', 'grade', 'network_risk_grade', 'email_risk_grade', 'web_risk_grade', 'dns_risk_grade', 'tls_risk_grade', 'certificates_risk_grade', 'cloud_risk_grade', 'attack_complexity']