import classNames from 'classnames';
import './CNAPPAlertGeneralTab.scss';
import ReactJson from 'react-json-view';
import { Common } from '../../../../../common/services/common.service';

const VALIDATIONS_MAP: any = {
  Unknown: { title: 'Validation Unknown', image: 'validation-status-na.svg', className: 'na' },
  True: { title: 'Validation Available', image: 'validation-status-available.svg', className: 'available' },
  False: { title: 'Validation Not Available', image: 'validation-status-na.svg', className: 'na' },
  Partially: {
    title: 'Validation Partially Available',
    image: 'validation-status-partially.svg',
    className: 'partially'
  }
};

const CNAPPAlertGeneralTab = (props: any) => {
  const getSourceIcon = (provider: string) => {
    if (provider?.toLowerCase().includes('wiz')) {
      return 'wiz';
    }

    if (provider?.toLowerCase().includes('prisma')) {
      return 'prisma';
    }
  };
  return (
    <div className="CNAPPAlertGeneralTab">
      <div className={classNames('validation')}>
        <div
          className={classNames('validation-status', VALIDATIONS_MAP[props.data.validatable || 'Unknown'].className)}
        >
          <div className="validation-status-wrapper">
            <img
              alt="Validation Status"
              src={`/assets/images/${VALIDATIONS_MAP[props.data.validatable || 'Unknown'].image}`}
            />
            <span>{VALIDATIONS_MAP[props.data.validatable || 'Unknown'].title}</span>
          </div>
          {Array.isArray(props.data.validatable_reasons) && (
            <div className="reasons-wrapper">
              Reason{props.data.validatable_reasons.length > 1 ? 's' : ''}:
              <ul>
                {props.data.validatable_reasons.map((reason: string) => {
                  return <li>{reason}</li>;
                })}
              </ul>
            </div>
          )}
        </div>
        {props.data.validation_status && (
          <div className={classNames('validation-info', props.data.validation_status.toLowerCase().replace(/ /g, '-'))}>
            <ul>
              {props.data.validation_status && (
                <li>
                  <h6>Validation Status</h6>
                  <p className="color">{props.data.validation_status}</p>
                </li>
              )}
              {props.data.validation_status_reasons && (
                <li>
                  <h6>Validation Status Reasons</h6>
                  <p>{props.data.validation_status_reasons.join(', ')}</p>
                </li>
              )}
              {props.data.validation_date && (
                <li>
                  <h6>Validation Date</h6>
                  <p>{props.data.validation_date}</p>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
      <div className="line"></div>
      <div className="alert-info">
        <div className="alert-info-header">
          <img alt={props.data.source} src={`/assets/images/${getSourceIcon(props.data.source)}-big.svg`} />
          <div>
            <h5>{props.data.source}</h5>
            <p>{props.data.asset}</p>
            <ul>
              {props.data.alert_id && <li>Alert ID: {props.data.alert_id}</li>}
              {props.data.alert_type && <li>Alert Type: {props.data.alert_type}</li>}
              {props.data.status && <li>Source Status: {props.data.status}</li>}
            </ul>
            {props.data.description && (
              <>
                <h6>Description</h6>
                <p>
                  {props.data.description ||
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"}
                </p>
              </>
            )}
            {props.data.recommendation && (
              <>
                <h6>Recommendation</h6>
                <p>
                  {props.data.recommendation ||
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"}
                </p>
              </>
            )}
            {props.data.validation_details && (
              <>
                <h6>Validation Details</h6>
                <ReactJson
                  iconStyle="square"
                  indentWidth={1}
                  name={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  src={props.data?.validation_details}
                  enableClipboard={(copy: any) => Common.copyText(copy.src)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CNAPPAlertGeneralTab;
