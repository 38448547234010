// @ts-nocheck
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addHeaders } from 'common/services/rest-api.service';
import { queryBuilder } from 'common/components/grid/query-builder.service';

export const getAggregateData = createAsyncThunk('topPanel/getAggregateData', async (data, thunkAPI) => {
  const state = thunkAPI.getState();
  // thunkAPI.signal.addEventListener('abort', () => {
  //   console.log('XXX: ', 'aborted');
  // });
  queryBuilder.setBaseURL(state.topPanel.topPanelInitialPath);
  queryBuilder.buildQuery(state.grid, state.general.globalFilter);

  let query = queryBuilder.getQuery();

  const res = await fetch(query, {
    method: 'GET',
    mode: 'cors',
    headers: addHeaders('GET'),
    credentials: 'include',
    signal: thunkAPI.signal
  }).then(data => {
    return data.json();
  });

  // if (!res.ok) {
  //   return rejectWithValue({ error: res });
  // }
  return res;
});

const topPanelSlice = createSlice({
  name: 'topPanel',
  initialState: {
    topPanelInitialPath: '',
    topPanelData: {},
    isLoading: false,
    topPanelContext: null
  },
  reducers: {
    setTopPanelContext: (state, { payload }) => {
      state.topPanelContext = payload;
    },
    setTopPanelInitialPath: (state, { payload }) => {
      state.topPanelInitialPath = payload;
    },
    setTopPanelIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    resetTopPanelState: state => {
      state.topPanelInitialPath = '';
      state.topPanelData = {};
      state.topPanelContext = null;
      state.isLoading = true;
    }
  },
  extraReducers: {
    [getAggregateData.pending]: state => {
      state.isLoading = true;
    },
    [getAggregateData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.topPanelData = { ...payload, context: state.topPanelContext };
    },
    [getAggregateData.rejected]: state => {
      state.isLoading = false;
    }
  }
});

const { actions, reducer } = topPanelSlice;
export const { setTopPanelContext, setTopPanelInitialPath, setTopPanelIsLoading, resetTopPanelState } = actions;
export default reducer;
