import React from 'react';

import { Text } from '@cyberpion/cyberpion-ui';
import './DiscoveryScanStatus.scss';

const iconCompleted = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6.00391" r="6" fill="#4FA686" />
    <path d="M8 5.00391L5.5625 8.00391L4 6.71819" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
const iconInProgress = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6.00391" r="6" fill="#DFB940" />
    <path
      d="M6 2.00391C7.06087 2.00391 8.07828 2.42533 8.82843 3.17548C9.57857 3.92562 10 4.94304 10 6.00391C10 7.06477 9.57857 8.08219 8.82843 8.83233C8.07828 9.58248 7.06087 10.0039 6 10.0039L6 6.00391L6 2.00391Z"
      fill="white"
    />
  </svg>
);
const iconQueued = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6.00391" r="5.25" stroke="#5D6576" stroke-width="1.5" stroke-dasharray="2 2" />
  </svg>
);
const iconFailed = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="6" r="6" fill="#5D6576" />
    <path d="M8.25 3.75L3.75 8.25" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.25 8.25L3.75 3.75" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const mapStatus = {
  Complete: { icon: iconCompleted, label: 'Completed' },
  'In Progress': { icon: iconInProgress, label: 'In Progress' },
  Queued: { icon: iconQueued, label: 'Queued' },
  Failed: { icon: iconFailed, label: 'Failed' }
};

const DiscoveryScanStatus = (props: any) => {
  return (
    <div className="DiscoveryScanStatus">
      <div className="icon">{mapStatus[props.value as keyof typeof mapStatus]?.icon || ''}</div>
      <Text style={{ marginLeft: 12 }}>{mapStatus[props.value as keyof typeof mapStatus]?.label || ''}</Text>
    </div>
  );
};

export default DiscoveryScanStatus;
