import { Button, Text } from '@cyberpion/cyberpion-ui';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'app/store';
import { setShouldMenuActionAffectAllRows } from 'features/DiscoveryToolSlice';

import './WarningDiscoveryPopup.scss';

const WarningDiscoveryPopup = (props: any) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleCancelOnClick = () => {
    dispatch(setShouldMenuActionAffectAllRows(false));
    props.setVisible(false);
  };

  return (
    <div className="WarningDiscoveryPopup popup-inner-wrapper">
      <div className="popup-inner">
        <Text textSize={18} weight={500} style={{ marginBottom: 12 }}>
          Action to all rows that match the filters
        </Text>
        <Text textSize={13} textColor="#8C909B" style={{ lineHeight: 1.35, marginBottom: 16 }}>
          You are about to apply an action to all grid rows that match the current filters. The manual selection of rows
          will not have any influence on this action.
        </Text>

        <div className="buttons-wrapper">
          <Button
            buttonStyle="secondary"
            disabled={false}
            type="button"
            size="small"
            onClick={handleCancelOnClick}
            text="Cancel"
          />
          <Button buttonStyle="main" disabled={false} type="button" size="small" onClick={props.onSave} text="Save" />
        </div>
      </div>
    </div>
  );
};

export default WarningDiscoveryPopup;
