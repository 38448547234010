import React, { memo, useMemo } from 'react';
import { Checkbox } from 'common/components/Icons/IconCheckbox/IconCheckbox';
import { useNotifications, NotificationsContextType } from 'pages/Settings/Notifications/NotificationsContext';
import {
  useNotificationConfiguration,
  NotificationConfigurationContextType
} from 'pages/Settings/Notifications/SidePanel/NotificationConfigurationContext';
import { REQUIRED_FILTERS } from 'pages/Settings/Notifications/SidePanel/utils';

interface ICheckbox {
  id: string;
  sortCompareFn?: (a: any, b: any) => number;
}

const defaultSortCompareFn = (a: any, b: any) => a.label.localeCompare(b.label)

const Checkboxes: React.FC<ICheckbox> = ({ id, sortCompareFn = defaultSortCompareFn }) => {
  const { notificationType } = useNotifications() as NotificationsContextType;
  const {
    notificationData,
    onConfigurationChange
  } = useNotificationConfiguration() as NotificationConfigurationContextType;

  const requiredFields: string[] = REQUIRED_FILTERS[notificationType as keyof typeof REQUIRED_FILTERS];
  const data = requiredFields.includes(id) ? notificationData : notificationData.filters;
  const areFilters = !!data && data[id];

  const handleOnChange = (selectedOption: any) => () => {
    const updatedOptions = data[id].choices.map((option: any) => {
      if (option.name === selectedOption.name) {
        return {
          ...option,
          is_toggled: !!option.is_toggled ? !option.is_toggled : true
        };
      }
      return option;
    });
    const toggledData = updatedOptions.filter((option: any) => !!option.is_toggled).map((item: any) => item.name);
    onConfigurationChange(id, toggledData);
  };

  const sortedChoices = useMemo(() => {
    return data[id].choices.sort(sortCompareFn)
  }, [data, id, sortCompareFn]);

  return (
    <div className="dropdown-box-checkbox">
      {!!areFilters &&
        sortedChoices.map((option: any) => {
          return (
            <div
              key={option.name}
              className="checkbox"
              onClick={handleOnChange(option)}
              data-testid={`dropdown-checkbox-${id}-${option.name}`}
            >
              <Checkbox active={option.is_toggled} />
              <div className="MGS-option-text" style={{ fontSize: 12 }}>
                {option.label}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default memo(Checkboxes);
