import React from 'react';
import { Text } from '@cyberpion/cyberpion-ui';
import { CveList } from 'common/components/popUp/components/Vulnerabilities/CveList/CveList';
import { TechnicalDetails } from 'common/components/TechnicalDetails/TechnicalDetails';
import { Grid } from '@mui/material';

import './actionItemDetails.scss';

interface IActionItemDetails {
  actionItem: any;
}

const ActionItemDetails: React.FC<IActionItemDetails> = ({ actionItem }) => {
  const { summary, description, technical_details, cves, impact, required_action, evidence } = actionItem;

  return (
    <Grid container className="action-item-details" flexDirection="column">
      <Grid item className="title">
        <Text textSize={11}>{summary}</Text>
      </Grid>
      <Grid item className="padding">
        <Text textSize={12} weight={600}>
          Description
        </Text>
      </Grid>
      <Grid item className="small-padding">
        <Text textSize={11} style={{ lineHeight: 1.5 }}>
          {description}
        </Text>
      </Grid>
      {!!impact && (
        <div className="padding">
          <Grid item>
            <Text textSize={12} weight={600}>
              Impact
            </Text>
          </Grid>
          <Grid item className="impact small-padding" sx={{ lineHeight: 1.5, fontSize: 11 }}>
            {impact}
          </Grid>
        </div>
      )}
      {cves && !!cves.length && (
        <>
          <Grid item className="padding">
            <Text textSize={12} weight={600}>
              CVE List
            </Text>
          </Grid>
          <Grid item className="cves small-padding">
            <CveList data={cves} visible={true} />
          </Grid>
        </>
      )}
      {!!technical_details && (
        <>
          <Grid item className="padding">
            <Text textSize={12} weight={600}>
              Technical Details
            </Text>
          </Grid>
          <Grid item className="small-padding technical-details-container">
            <TechnicalDetails data={technical_details} isActionItemsPDF={true} />
          </Grid>
        </>
      )}
      {!!evidence && !!evidence.length && (
        <>
          <Grid item className="padding">
            <Text textSize={12} weight={600}>
              Discovery Evidence
            </Text>
          </Grid>
          <Grid item className="small-padding technical-details-container">
            <TechnicalDetails data={evidence} isActionItemsPDF={true} />
          </Grid>
        </>
      )}
      {!!required_action && (
        <div className="padding">
          <Grid item>
            <Text textSize={12} weight={600}>
              Required Action
            </Text>
          </Grid>
          <Grid item className="required-action small-padding" sx={{ lineHeight: 1.5, fontSize: 11 }}>
            {required_action}
          </Grid>
        </div>
      )}
    </Grid>
  );
};

export default ActionItemDetails;
