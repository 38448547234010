// import { IconAcknowledgeHide } from 'common/components/Icons/IconAcknowledgeHide/IconAcknowledgeHide';
import { IconAddTag } from 'common/components/Icons/IconAddTag/IconAddTag';
import { IconRemoveTag } from 'common/components/Icons/IconRemoveTag/IconRemoveTag';
import { IconRescan } from 'common/components/Icons/IconRescan/IconRescan';
import { IconStatuses } from 'common/components/Icons/IconStatuses/IconStatuses';
// import { IconUncknowledge } from 'common/components/Icons/IconUncknowledge/IconUncknowledge';
import { PathNames } from 'common/constants/pathNames';

interface ITabset {
  [key: string]: ITab;
}
interface ITab {
  title: string;
  key: string;
  link: string;
  permissionOpen: boolean;
  active: boolean;
  showSummary?: boolean;
  columnPickerPath: string;
  gridPath: string;
  tabPath: string;
  count: number;
  savedViews?: boolean;
  defaultView?: string;
  featureFlag?: string;
}

export const TABSET: ITabset = {
  open: {
    title: 'Open Action Items',
    key: 'open_action_items',
    link: '/pages/actionItems/open',
    defaultView:
      '/pages/actionItems/open?fields=id%2Curgency%2Cis_active_protection%2Ctype%2Ctitle%2Casset%2Casset_importance%2Cfirst_opened_at%2Clast_opened_at%2Cis_acknowledged%2Ctags%2Cgroups&filters=%7B"current_state__in"%3A"open%2Cin_progress"%7D',
    permissionOpen: true,
    active: false,
    showSummary: true,
    columnPickerPath: PathNames.actionItemsOpenColumns,
    gridPath: PathNames.actionItemsOpen,
    tabPath: PathNames.actionItemsOpenSummary,
    count: 0,
    savedViews: true
  },
  closed: {
    title: 'Closed Action Items',
    key: 'closed_action_items',
    link: '/pages/actionItems/closed',
    defaultView:
      '/pages/actionItems/closed?fields=id%2Curgency%2Cis_active_protection%2Ctype%2Ctitle%2Casset%2Casset_importance%2Cfirst_opened_at%2Clast_opened_at%2Clast_closed_at%2Cresolution_time%2Cis_acknowledged%2Ctags%2Cgroups',
    permissionOpen: true,
    active: false,
    showSummary: true,
    columnPickerPath: PathNames.actionItemsCloseColumns,
    gridPath: PathNames.actionItemsClose,
    tabPath: PathNames.actionItemsCloseSummary,
    count: 0,
    savedViews: true
  },
  alerts: {
    title: 'Cloud Exposure Validation',
    key: 'cnapp_alerts_validation',
    link: '/pages/actionItems/alerts?filters=%7B"validatable__in"%3A"1"%7D',
    defaultView:
      '/pages/actionItems/alerts?fields=severity%2Csuggested_new_severity%2Ctitle%2Casset%2Csource%2Cupdate_time%2Ccloud_provider%2Cvalidatable_reasons%2Cvalidation_status_reasons%2Cvalidation_date%2Cionix_asset%2Cgroups&filters=%7B"validatable__in"%3A"1"%7D',
    permissionOpen: true,
    active: false,
    showSummary: false,
    columnPickerPath: PathNames.actionItemsCnappAlertsColumns,
    gridPath: PathNames.actionItemsCnappAlerts,
    tabPath: '',
    count: 0,
    savedViews: true,
    featureFlag: 'isCnappAlertValidation'
  }
};

// export const OPTIONS = [
//   {
//     IconComponent: IconRescan,
//     label: 'Rescan'
//   },
//   {
//     IconComponent: IconAcknowledgeHide,
//     label: 'Acknowledge'
//   },
//   {
//     IconComponent: IconUncknowledge,
//     label: 'Unacknowledge'
//   },
//   {
//     IconComponent: IconAddTag,
//     label: 'Add Tags',
//     ff: 'isCustomTags'
//   },
//   {
//     IconComponent: IconRemoveTag,
//     label: 'Remove Tags',
//     ff: 'isCustomTags'
//   }
// ];

export const OPTIONS = [
  {
    IconComponent: IconRescan,
    label: 'Rescan'
  },
  {
    IconComponent: IconAddTag,
    label: 'Add Tags',
    ff: 'isCustomTags'
  },
  {
    IconComponent: IconRemoveTag,
    label: 'Remove Tags',
    ff: 'isCustomTags'
  },
  {
    IconComponent: IconStatuses,
    label: 'Update Status',
    subMenuType: 'dropdown_with_action'
  }
];
